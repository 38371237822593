<template>
  <RegularPage>
    <div class="main-statement">
      Tark Audio was established in 2022 in Portland, OR.
      The goal of Tark Audio is to provide inspiring, original, and engaging audio products.
      <br><br>
      Tark Audio products are designed to help illuminate unexplored areas of sonic cartography.
    </div>
    <div class="team-container">
      <div class="team-section-title">The Team</div>
      <div class="team-member ta-section">
        <div class="team-member-name ta-section-title">Marc</div>
        <div class="team-member-image-container"><img style="width: 100%;" src="@/assets/team/marc-1.jpg"/></div>
        <div class="team-member-bio ta-section-content">
          Marc is the founder of Tark Audio.  
          His interest in musical devices started in his early teens when he picked up a guitar for the first time.
          Playing guitar opened the door to producing music with friends and computers.
          <br><br>
          During the first year college, Marc got a job as a Live Sound Engineer and realized his passion for audio electronics - electrons moved everything around him.
          He switched majors to Electrical Engineering and Mathematics in order to understand and control this magic.
          To balance the constriants of work and school, he explored the sonic arts by playing in and recording bands on tape machines and computers.
          <br><br>
          After college, Marc started an audio electronics repair business.  Through dissaembly, repair, and re-assembly, he learned about product design.
          He kept sharpening his engineering skills and started a career in designing hardware and firmware for high-tech embedded systems.
          Tark Audio was formed by combining Marc's engineering abilities with his passion for audio devices.
        </div>
      </div>
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'

export default {
  name: 'About',
  components: {
    RegularPage
  }
}
</script>


<style lang="scss">
@import "@/styles/style.scss";

.main-statement {
  padding: 10px;
  margin: 40px 20px;
  font-size: $text-size--lg;
}

.team-section-title {
  margin: 20px;
  font-size: $text-size--xl;
  font-weight: bold;
  text-align: center;
}

.section-title {
  padding: 10px;
  margin: auto;
  font-weight: bold;
  font-size: $text-size--xl;
  border-radius: 10px;
  align-items: center;
  background-color: $color--grey2;
}


.team-member-image-container {
  max-width: 400px;
  width: 90%;
  margin: 30px auto;
}
</style>