<template>
  <div class="productPrice">
    <div v-if="onSale">
      <div class="productPrice-sale-original">Price: ${{ price }}</div>
      <div class="productPrice-sale-newPrice">Sale Price: ${{ salePrice }}</div>
    </div>
    <div v-else>
      <div class="productPrice-regular">Price: ${{ price }}</div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ProductPrice',
  props: {
    price: Number,
    salePrice: Number,
    onSale: Boolean
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/productPageStyles.scss";
.productPrice {
  min-height: 80px;
}

.productPrice-regular {
  font-size: 28px;
  font-weight: bold;
  color: $color--white;
}

.productPrice-sale-original {
  font-weight: bold;
  font-size: 24px;
  color: $color--grey3;
  text-decoration: line-through;
}


.productPrice-sale-newPrice {
  font-size: 28px;
  font-weight: bold;
  color: $color--green;

}
</style>