<template>
  <div class="carousel">
    <div class="carousel-image-container">
      <img :src="currentImage" alt="Carousel Image" class="carousel-image" />
      <button class="carousel-prev-button" @click="prevImage">
        <img src="@/assets/btnPrev.svg" alt="Previous Button" />
      </button>
      <button class="carousel-next-button" @click="nextImage">
        <img src="@/assets/btnNext.svg" alt="Next Button" />
      </button>
    </div>
  </div>
</template>



<script>
export default {
  name: 'Carousel',

  props: {
    images: Array
  },

  data() {
    return {
      currentIndex: 0
    };
  },

  computed: {
    currentImage() {
      return require('@/assets/' + this.images[this.currentIndex]);
    }
  },

  methods: {
    prevImage() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";
.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  
  border-radius: 20px;
}

.carousel-prev-button,
.carousel-next-button {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 2; /* Ensure buttons are on top of the image */
}

.carousel-prev-button img,
.carousel-next-button img {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
}

.carousel-prev-button {
  left: 10px;
}

.carousel-next-button {
  right: 10px;
}
</style>
