<template>
  <RegularPage>
    <ProductPageShowcase :productName="name"
      :userManualPath="`${userManualPath}`" :quickSpecs="[
          { key: 'TYPE:', value: 'Oscillator' },
          { key: 'HP:', value: '4 HP' },
          { key: 'DEPTH:', value: '24mm' },
          { key: 'POWER:', value: '10 pin' },
          { key: 'CURRENT:', value: '+12V: 90mA -12V: 10mA' }
        ]" />

    <!-- BEGIN sections -->
    <div class="sections">
      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SAMPLES</div>
        <div class="section-content ta-section-content">
          <ProductPageSampleScope :samples="[
              { desc: 'Vari-PWM', path: `${publicPath}static/products/lambdaosc/audio/lambdaosc_clip1.wav` },
              { desc: 'Super', path: `${publicPath}static/products/lambdaosc/audio/lambdaosc_clip2.wav` },
              { desc: 'Bell', path: `${publicPath}static/products/lambdaosc/audio/lambdaosc_clip3.wav` },
              { desc: 'Phase Mod.', path: `${publicPath}static/products/lambdaosc/audio/lambdaosc_clip4.wav` },
              { desc: 'Hard Sync', path: `${publicPath}static/products/lambdaosc/audio/lambdaosc_clip5.wav` },
              { desc: 'Hard Rev FM', path: `${publicPath}static/products/lambdaosc/audio/lambdaosc_clip6.wav` }
            ]" />
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">DESCRIPTION</div>
        <div class="section-content long-description ta-section-content">
          LAMBDA OSC is a 4 HP Multi-Algorithm Digital Oscillator. It offers six oscillator algorithms designed to give a
          familiar but unique start to a synthesis chain. These algorithms are:<br /> <br />
          <ul>
            <li>Vari-PWM</li>
            <li>Super</li>
            <li>Bell Tone</li>
            <li>Phase Modulation</li>
            <li>Hard Sync</li>
            <li>Hard Reverse Sync FM</li>
          </ul>
          See the BANKs section of the <a :href="`${userManualPath}`" target="_blank">User Manual</a> for a description of each algorithm.
          These algorithms are tailored with novel sync implementations to give clean, sync-able waveforms over the
          audible frequency range.<br><br>
          Each algorithm (BANK) has two parameters - WAVE and MOD. The WAVE and MOD parameters are CV’able through CV
          Attenuators. The BANK button is used to cycle through the BANKs and to switch MENUs. A multi-color LED displays 
          the current BANK/MENU/STATUS. See the OPERATION section of the <a :href="`${userManualPath}`" target="_blank">User  Manual</a>
          for the LED Status, BANK Parameters, and MENU Parameters Tables.<br><br>
          The frequency is controlled using the Volt Per Octave (VPO) input. The VPO input accepts voltages in the
          +/- 10V range and tracks well into the higher octaves. Driving the VPO input with negative voltages will
          lower the output frequency to LFO range. The base frequency can be adjusted +/- 3 semitones (centered around C)
          using the FREQ knob. The octave is set using the OCT knob. The OCT parameter can be CV’d through a CV
          Attenuator. There are 7 octaves to scan through.<br><br>
          Each BANK handles the SYNC/FM input differently. There are three different kinds of Sync available - Hard, Hard
          Reverse, and Phase Advance. See the SYNCHRONIZATION MODES section of the <a
            :href="`${userManualPath}`" target="_blank">User
            Manual</a> for a description of each.
          When a Sync signal is detected, the FREQ control changes to +/- 4 octaves. This allows for tactile sync-sweeps
          with the base frequency set by the Leader Oscillator.
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SPECS</div>
        <div class="section-content ta-section-content">
          <div class="specs">
            <div class="specs-key">HP:</div>
            <div class="specs-value">4 HP</div>
            <div class="specs-key">DEPTH:</div>
            <div class="specs-value">24mm<br>(0.95")</div>
            <div class="specs-key">POWER CONNECTOR:</div>
            <div class="specs-value">10 pin, stripe negative</div>
            <div class="specs-key">CURRENT DRAW:</div>
            <div class="specs-value">+12V: 90mA<br>-12V: 10mA</div>
            <div class="specs-key">WEIGHT:</div>
            <div class="specs-value">52 grams</div>
            <div class="specs-key">SAMPLE RATE:</div>
            <div class="specs-value">96kHz</div>
            <div class="specs-key">BIT DEPTH:</div>
            <div class="specs-value">24bit<br>processed as 32-bit floats</div>
            <div class="specs-key">INPUT IMPEDANCE:</div>
            <div class="specs-value">100kOhm (all inputs)</div>
            <div class="specs-key">OUTPUT IMPEDANCE:</div>
            <div class="specs-value">1kOhm (all outputs)</div>
            <div class="specs-key">INPUT VOLTAGE RANGE:</div>
            <div class="specs-value">+/- 5V nominal<br>+/- 10V max</div>
            <div class="specs-key">OUTPUT VOLTAGE RANGE:</div>
            <div class="specs-value">10Vpp, +/- 5V<br>some signals may exceed +/-5V</div>
            <div class="specs-key">OUTPUT FREQUENCY RANGE:</div>
            <div class="specs-value">0.01Hz to 20kHz</div>
            <div class="specs-key">SYNC FREQUENCY RANGE:</div>
            <div class="specs-value">10Hz to 20kHz</div>
            <div class="specs-key">VOLT PER OCTAVE RANGE:</div>
            <div class="specs-value">&gt; 8 oct<br>+/- 4 cents for fout &lt; 8kHz</div>
          </div>
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">RESOURCES</div>
        <div class="section-content ta-section-context">
          <a class="productpage-user-manual"
            :href="`${userManualPath}`" target="_blank">User
            Manual</a>
        </div>
      </div>

      <!-- END sections -->
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import ProductPageShowcase from '@/components/ProductPageShowcase.vue'
import ProductPageSampleScope from '@/components/ProductPageSampleScope.vue'

import { getStaticProductDetails } from '@/js/StaticProductDetails.js'


export default {
  name: 'ProductPage_LambdaOsc',
  components: {
    RegularPage,
    ProductPageShowcase,
    ProductPageSampleScope
  },
  data() {
    return {
      name: 'lambdaosc',
      publicPath: process.env.BASE_URL,
      userManualPath: null
    }
  },
  created() {
    this.userManualPath = `${this.publicPath}${getStaticProductDetails(this.name).userManual}`
  }
}
</script>


<style lang="scss">
@import "@/styles/productPageStyles.scss";
</style>