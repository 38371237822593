<template>
  <RegularPage>
    <ProductPageShowcase :productName="name" :userManualPath="`${userManualPath}`" :quickSpecs="[
      { key: 'TYPE:', value: 'Color/Dynamics/VCA' },
      { key: 'HP:', value: '10 HP' },
      { key: 'DEPTH:', value: '30mm' },
      { key: 'POWER:', value: '10 pin' },
      { key: 'CURRENT:', value: '+12V: 35mA -12V: 20mA' }
    ]"/>

    <!-- BEGIN sections -->
    <div class="sections">

      <!-- TODO: update videos -->
      <div class="section-container ta-section">
        <div class="section-title ta-section-title">VIDEOS</div>
        <ProductPageVideoTabs :videos="[
          { title: 'Tark Audio', id: 'EuUiT5TstPI' }
        ]" />
      </div>

      <!-- TODO: update samples -->
      <!-- <div class="section-container ta-section">
        <div class="section-title ta-section-title">SAMPLES</div>
        <div class="section-content ta-section-content">
          <ProductPageSampleScope :samples="[
            { desc: 'Bite Rotation', path: `${publicPath}static/products/tark144euro/audio/bite_rotation.wav` },
            { desc: 'Bite 0%', path: `${publicPath}static/products/tark144euro/audio/bite_0.wav` },
            { desc: 'Bite 50%', path: `${publicPath}static/products/tark144euro/audio/bite_50.wav` },
            { desc: 'Bite 100%', path: `${publicPath}static/products/tark144euro/audio/bite_100.wav` },
          ]" />
        </div>
      </div> -->

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">DESCRIPTION</div>
        <div class="section-content long-description ta-section-content">
          The TARK 144 is a faithful replica of a vintage cassette tape machine channel strip. The circuit consists of a
          JFET preamp followed by an active tone control.<br /><br />

          The TARK 144 elaborates on the channel strip design by adding a saturating dynamics circuit between the
          JFET preamp and tone control circuitry. This circuit was added to emulate the noise reduction compander
          characteristic of early tape machines.<br /><br />

          The dynamics circuit has three modes: OFF, INT + CV, and CV<br /><br />

          In OFF mode, the dynamics circuitry is bypassed.<br /><br />

          In INT + CV mode, the dynamics are modulated by the sum of an internal envelope follower and an external cv
          applied to the DYN CV input. This mode is designed to allow chained dynamics when using two TARK 144’s in
          stereo.<br /><br />

          In CV mode, the dynamics are controlled only by the external cv applied to the DYN CV input. This mode allows
          the TARK 144 to act as a saturating VCA with tone control.<br /><br />

          The internal envelope follower is tapped out to the ENV output. The DYN CV input is normalled to the ENV
          output.<br /><br />

          IN 1 and IN 2 are summed and attenuated before being fed to the JFET preamp circuit, which
          is tapped at the PRE output. The VU Meter shows the output signal level.
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SPECS</div>
        <div class="section-content ta-section-content">
          <div class="specs">
            <div class="specs-key">HP:</div>
            <div class="specs-value">10 HP</div>
            <div class="specs-key">DEPTH:</div>
            <div class="specs-value">30mm<br>(1.2")</div>
            <div class="specs-key">POWER CONNECTOR:</div>
            <div class="specs-value">10 pin, stripe negative</div>
            <div class="specs-key">CURRENT DRAW:</div>
            <div class="specs-value">+12V: 35mA<br>-12V: 20mA</div>
            <div class="specs-key">WEIGHT:</div>
            <div class="specs-value">105 grams</div>
            <div class="specs-key">INPUT IMPEDANCE:</div>
            <div class="specs-value">100kOhm, 500kOhm</div>
            <div class="specs-key">OUTPUT IMPEDANCE:</div>
            <div class="specs-value">1kOhm (all outputs)</div>
            <div class="specs-key">INPUT VOLTAGE RANGE:</div>
            <div class="specs-value">+/- 5V nominal<br>+/- 10V max</div>
            <div class="specs-key">OUTPUT VOLTAGE RANGE:</div>
            <div class="specs-value">+/- 10V max</div>
          </div>
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">RESOURCES</div>
        <div class="section-content ta-section-context">
          <a class="productpage-user-manual" :href="`${userManualPath}`" target="_blank">User
            Manual</a>
        </div>
      </div>

      <!-- END sections -->
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import ProductPageShowcase from '@/components/ProductPageShowcase.vue'
import ProductPageSampleScope from '@/components/ProductPageSampleScope.vue'
import ProductPageVideoTabs from '@/components/ProductPageVideoTabs.vue'

import { getStaticProductDetails } from '@/js/StaticProductDetails.js'


export default {
  name: 'ProductPage_Tark144Euro',
  components: {
    RegularPage,
    ProductPageShowcase,
    ProductPageSampleScope,
    ProductPageVideoTabs
  },
  data() {
    return {
      name: 'tark144euro',
      publicPath: process.env.BASE_URL,
      userManualPath: null
    }
  },
  created() {
    this.userManualPath = `${this.publicPath}${getStaticProductDetails(this.name).userManual}`
  }
}
</script>


<style lang="scss">
@import "@/styles/productPageStyles.scss";
</style>