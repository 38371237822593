<template>
  <RegularPage>
    <ProductPageShowcase :productName="name"
      :userManualPath="`${userManualPath}`" :quickSpecs="[
          { key: 'TYPE:', value: 'Echo/Delay' },
          { key: 'HP:', value: '4 HP' },
          { key: 'DEPTH:', value: '24mm' },
          { key: 'POWER:', value: '10 pin' },
          { key: 'CURRENT:', value: '+12V: 90mA -12V: 10mA' }
        ]" />

    <!-- BEGIN sections -->
    <div class="sections">
      <!-- <div class="section-container ta-section">
        <div class="section-title ta-section-title">SAMPLES</div>
        <div class="section-content ta-section-content">
          <ProductPageSampleScope :samples="[
              { desc: 'Vari-PWM', path: `${publicPath}static/products/echosync/audio/echosync_clip1.wav` },
              { desc: 'Super', path: `${publicPath}static/products/echosync/audio/echosync_clip2.wav` },
              { desc: 'Bell', path: `${publicPath}static/products/echosync/audio/echosync_clip3.wav` },
              { desc: 'Phase Mod.', path: `${publicPath}static/products/echosync/audio/echosync_clip4.wav` },
              { desc: 'Hard Sync', path: `${publicPath}static/products/echosync/audio/echosync_clip5.wav` },
              { desc: 'Hard Rev FM', path: `${publicPath}static/products/echosync/audio/echosync_clip6.wav` }
            ]" />
        </div>
      </div> -->

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">DESCRIPTION</div>
        <div class="section-content long-description ta-section-content">
          ECHO SYNC is a 4 HP Syncable Echo Module. In addition to the regular delay
          parameters of Time, Feedback, and Mix, it offers delay tone control, internal time
          modulation, reverb, and two time change algorithms. The signal paths are
          crafted with Virtual Analog filters and Tark Audio’s Derivative-Tapering Saturators to
          give clean, spacious sounds that can be pushed into squishy break-up.<br /><br />

          The two time change algorithms are Jump and Glide. In Jump mode, the delay time
          can be changed without causing pitch shifts. In Glide mode, changing the delay time
          shifts the output pitch like an analog delay unit. Both algorithms are illustrated in the
          TIME CHANGE ALGORITHM Section of the <a :href="`${userManualPath}`" target="_blank">User Manual</a>.<br /><br />

          The delay time can be synchronized to an external clock with eleven tempo divisions.
          The Jump time change algorithm is designed to allow for modulated rhythmic changes
          without changing the pitch. The Time and Feedback parameters are CV’able through
          CV Attenuators, so complex rhythms can be generated using external control sources.<br /><br />
          
          A multi-color LED displays the current mode and delay time information. The LED fades at the
          delay time when in Free-Run mode and blinks at the detected tempo in Sync mode.
          The maximum delay time is 2.73 seconds.
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SPECS</div>
        <div class="section-content ta-section-content">
          <div class="specs">
            <div class="specs-key">HP:</div>
            <div class="specs-value">4 HP</div>
            <div class="specs-key">DEPTH:</div>
            <div class="specs-value">24mm<br>(0.95")</div>
            <div class="specs-key">POWER CONNECTOR:</div>
            <div class="specs-value">10 pin, stripe negative</div>
            <div class="specs-key">CURRENT DRAW:</div>
            <div class="specs-value">+12V: 90mA<br>-12V: 10mA</div>
            <div class="specs-key">WEIGHT:</div>
            <div class="specs-value">52 grams</div>
            <div class="specs-key">SAMPLE RATE:</div>
            <div class="specs-value">48kHz</div>
            <div class="specs-key">BIT DEPTH:</div>
            <div class="specs-value">24bit<br>processed as 32-bit floats</div>
            <div class="specs-key">INPUT IMPEDANCE:</div>
            <div class="specs-value">100kOhm (all inputs)</div>
            <div class="specs-key">OUTPUT IMPEDANCE:</div>
            <div class="specs-value">1kOhm (all outputs)</div>
            <div class="specs-key">INPUT VOLTAGE RANGE:</div>
            <div class="specs-value">+/- 5V nominal<br>+/- 10V max</div>
            <div class="specs-key">OUTPUT VOLTAGE RANGE:</div>
            <div class="specs-value">+/- 10V max</div>
            <div class="specs-key">DELAY TIME RANGE:</div>
            <div class="specs-value">1 msec to 2.73 seconds</div>
            <div class="specs-key">CLK SYNC RANGE:</div>
            <div class="specs-value">1 msec to 2.73 seconds<br />(0.37Hz to 1kHz)</div>
          </div>
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">RESOURCES</div>
        <div class="section-content ta-section-context">
          <a class="productpage-user-manual"
            :href="`${userManualPath}`" target="_blank">User
            Manual</a>
        </div>
      </div>

      <!-- END sections -->
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import ProductPageShowcase from '@/components/ProductPageShowcase.vue'
import ProductPageSampleScope from '@/components/ProductPageSampleScope.vue'

import { getStaticProductDetails } from '@/js/StaticProductDetails.js'


export default {
  name: 'ProductPage_EchoSync',
  components: {
    RegularPage,
    ProductPageShowcase,
    ProductPageSampleScope
  },
  data() {
    return {
      name: 'echosync',
      publicPath: process.env.BASE_URL,
      userManualPath: null
    }
  },
  created() {
    this.userManualPath = `${this.publicPath}${getStaticProductDetails(this.name).userManual}`
  }
}
</script>


<style lang="scss">
@import "@/styles/productPageStyles.scss";
</style>