<template>
  <div class="cartItem-container">
    <div class="cartItem-image-container"><img class="cartItem-img" :src="require(`@/assets/${imgSrc}`)" /></div>
    <div class="cartItem-text-container">
      <div class="cartItem-title-desc-container">
        <div class="cartItem-title">{{title}}</div>
        <div class="cartItem-short-description">{{shortDescription}}</div>
        <div v-if="numVariants > 1" class="cartItem-variantName">variant: {{variantName}}</div>
      </div>
    </div>
    <div class="cartItem-text-container">
      <div class="cartItem-price-container">
        <div class="cartItem-price-line">
          <div class="cartItem-price-line-label">Unit Price:</div>
          <div class="cartItem-price-line-price">${{unitPrice}}</div>
        </div>
        <div class="cartItem-price-line">
          <div class="cartItem-price-line-label">Price:</div>
          <div class="cartItem-price-line-price">${{price}}</div>
        </div>
      </div>
    </div>
    <div class="cartItem-quantity-container">
      <button v-if="quantity < quantityMax" type="button" class="ta-button cartItem-quantity-button" @click="$emit('quantityInc')">+</button>
      <button v-else type="button" class="ta-button cartItem-quantity-button disabled">+</button>
      <div class="cartItem-quantity">{{quantity}}</div>
      <button v-if="quantity > 1" type="button" class="ta-button cartItem-quantity-button" @click="$emit('quantityDec')">-</button>
      <button v-else type="button" class="ta-button cartItem-quantity-button disabled">-</button>
    </div>
    <div class="cartItem-remove-button-container">
      <button type="button" class="ta-button cartItem-remove-button" @click="$emit('remove')">x</button>
    </div>
  </div>
</template>


<script>
export default {
  name: 'CartItem',
  props: {
    title: String,
    shortDescription: String,
    variantName: String,
    numVariants: Number,
    imgSrc: String,
    unitPrice: Number,
    price: Number,
    quantity: Number,
    quantityMax: Number
  }
}
</script>


<style lang="scss">
@import "@/styles/style.scss";

.cartItem-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid;
  border-radius: 10px;
  width: 100%;
  height: 180px;
  padding: 10px;
}

.cartItem-image-container {
  flex-basis: 15%;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90%;
}

.cartItem-img {
  height: 100%;
  max-width: 120px;
  padding: 10px;
}

.cartItem-text-container {
  flex-basis: 30%;
  display: flex;
  align-items: center;
}

.cartItem-title-desc-container {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
}

.cartItem-title {
  font-size: $text-size--xs;
}

.cartItem-short-description {
  font-size: $text-size--xs;
  color: $color--grey2;
}

.cartItem-variantName {
  font-size: $text-size--xs;
  color: $color--grey3;
}

.cartItem-price-container {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  width: 100%;
}

.cartItem-price-line {
  display: flex;
  flex-direction: row;
  font-size: $text-size--xxs;
  margin: 5px;
  width: 100%;
}

.cartItem-price-line-label {
  flex-basis: 50%;
  text-align: right;
  font-size: $text-size--xxs;
  color: $color--grey2;
  margin: 0 10px;
}

.cartItem-price-line-price {
  flex-basis: 50%;
  font-size: $text-size--xxs;
  color: $color--grey1;
}

.cartItem-quantity-container {
  flex-basis: 5%;
  justify-content: center;
  text-align: center;
}

.cartItem-quantity {
  margin: 10px;
  text-align: center;
}

.ta-button.cartItem-quantity-button {
  width: 40px;
  height: 40px;
  padding: 6px;
}

.ta-button.cartItem-quantity-button.disabled {
  background-color: $color--grey5;
  filter: none;
}

.ta-button.cartItem-quantity-button.disabled:active {
  transform: none;
}

.cartItem-remove-button-container {
  flex-basis: 5%;
  margin: 10px;
}

.ta-button.cartItem-remove-button {
  width: 40px;
  height: 40px;
  padding: 6px;
  background-color: $color--red;
  filter: drop-shadow(0 0 4px $color--red);
}

@media only screen and (max-width: $bkpt--lg) {
  .cartItem-title-desc-container {
    margin: 10px 15px;
  }
  
  .cartItem-price-container {
    margin: 10px 15px;
  }
}


@media only screen and (max-width: $bkpt--sm) {
  .cartItem-image-container {
    display: none;
  }

  .cartItem-price-line {
    text-align: center;
    flex-direction: column;
  }

  .cartItem-price-line-label{
    text-align: center;
  }
}

@media only screen and (max-width: $bkpt--xs) {
  .cartItem-text-container {
    flex-direction: column;
  }
  
  .cartItem-title-desc-container {
    margin: 10px 10px;
  }
  
  .cartItem-price-container {
    margin: 10px 10px;
  }
}
</style>