<template>
  <router-view />
  <CookieBanner />
</template>


<script>
import { cartManager } from './js/CartManager.js'

import CookieBanner from './components/CookieBanner.vue';

export default {
  name: 'App',
  components: {
    CookieBanner
  },
  async created() {
    await cartManager.init()
  }
}
</script>


<style lang="scss">
@import "@/styles/style.scss";
</style>
