import p5 from 'p5'
import { defineSketch } from './p5_helper'

export class OscopeClass {
  constructor(canvasParent, width, height, color, colorGrad, waveform) {
    this.canvasParent = canvasParent
    this.width = width
    this.height = height
    this.color = color
    this.colorGrad = colorGrad
    this.waveform = waveform

    this.p = {}       // pointer to p5
    this.canvas = {}  // pointer to canvas

    this.mySketch = defineSketch(this)
    new p5(this.mySketch)
  }

  setCanvasParent(canvasParent) {
    if (this.canvas) {
      this.canvasParent = canvasParent
      this.canvas.parent(this.canvasParent)
    }
  }

  setWaveform(waveform) {
    this.waveform = waveform
  }

  resize(dims) {
    if (this.p) {
      this.width = dims.width
      this.height = dims.height
      this.p.resizeCanvas(this.width, this.height)
    }
  }

  // setup function for p5
  setup = (p) => {
    try {
      this.p = p
      this.canvas = p.createCanvas(this.width, this.height)
      this.canvas.parent(this.canvasParent)
      this.p.textFont('Inconsolata')
    }
    catch (e) {
      console.log(e)
    }
  }

  // draw function for p5
  draw = (p) => {
    p.clear()
    p.background('rgba(100%,100%,100%,0)')
    let buffer = this.waveform.getValue(0)

    // draw graticule
    for (var x = 0; x < p.width; x += p.width / 8) {
      for (var y = 0; y < p.height; y += p.height / 8) {
        var y2 = p.map(y, 0, p.height, 0, 1)
        var a = -1 * Math.pow(2 * y2 - 1, 2) + 1
        if (a < 0) { a = 0 }
        p.stroke(`rgba(${this.colorGrad.r}, ${this.colorGrad.g}, ${this.colorGrad.b}, ${a})`)

        p.strokeWeight(0.25);
        p.line(x - 2, 0, x - 2, p.height);
        p.line(0, y, p.width, y);
      }
    }

    // text
    p.fill(0, 255, 0);
    p.textSize(12);
    p.text('TARKTRONICS', p.width - 80, p.height - 20);


    // look a trigger point where the samples are going from
    // negative to positive
    let start = 0
    for (let i = 1; i < buffer.length; i++) {
      if ((buffer[i - 1] < 0) && (buffer[i] >= 0)) {
        start = i
        break // interrupts a for loop
      }
    }

    // calculate a new end point such that we always
    // draw the same number of samples in each frame
    start += buffer.length / 8;
    let end = start + buffer.length / 2;

    // drawing the waveform
    for (let i = start; i < end; i++) {
      let x1 = p.map(i - 1, start, end, 0, p.width);
      let y1 = p.map(buffer[i - 1], -1, 1, p.height, 0);
      let x2 = p.map(i, start, end, 0, p.width);
      let y2 = p.map(buffer[i], -1, 1, p.height, 0);
      p.line(x1, y1, x2, y2);

      var x_a = p.map(i, start, end, 0, 1)
      var a = -1 * Math.pow(2 * x_a - 1, 2) + 1
      if (a < 0) { a = 0 }

      p.strokeWeight(3)
      p.stroke(`rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${a})`)
      // p.stroke(`rgb(${this.color.r}, ${this.color.g}, ${this.color.b})`)
    }
  }

  cleanup() {
    try {
      this.p.noCanvas();
    } catch (e) {
      console.log("oscope - no canvas found to remove");
      console.log(e);
    }
  }
}
