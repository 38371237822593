<template>
  <RegularPage>
    <div class="thank-you">Thank you for your purchase!</div>
    <div class="message">Please do not refresh this page!</div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import { cartManager } from '@/js/CartManager.js'
import { firebaseHelper } from '@/js/FirebaseHelper.js'

export default {
  name: 'StripeConfirmation',
  components: {
    RegularPage
  },
  created() {
    const uri = this.$route.fullPath;
    const params = new URLSearchParams(uri.substring(uri.indexOf('?') + 1));
    const lineItems = [];

    let currentItem = {};
    params.forEach((value, key) => {
      if (key === 'name') {
        if (currentItem.name) {
          lineItems.push(currentItem);
          currentItem = {};
        }
        currentItem.name = value;
      } else if (key === 'variantId') {
        currentItem.variantId = parseInt(value);
      } else if (key === 'quantity') {
        currentItem.quantity = parseInt(value);
      }
    });

    // Add the last item to the lineItems array
    if (currentItem.name) {
      lineItems.push(currentItem);
    }

    const stripeHandlePostCheckout = firebaseHelper.getHttpsCallableFunction('stripeHandlePostCheckout')
    stripeHandlePostCheckout({ lineItems: lineItems })
      .then((response) => {
        // handle success
        console.log(response.data)
      })
      .catch((error) => {
        // handle error
        // TODO: better error handling
        console.log(error);
      })
      .finally(() => {
        // always executed
      });

    cartManager.clear()
    this.$router.push('/stripe-payment-thank-you')
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";
.thank-you {
  font-size: $text-size--xl;
  margin: 20px;
}

.message {
  font-size: $text-size--md;
  margin: 40px;
}
</style>