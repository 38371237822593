<template>
  <div class="foot">
    <div class="foot-container">
      <div class="foot-text-container">
        <div class="foot-text">
          <router-link to="/faq">FAQ</router-link>
        </div>
        <div class="foot-text">
          <router-link to="/about">About</router-link>
        </div>
        <div class="foot-text">
          <router-link to="/contactus">Contact Us</router-link>
        </div>
        <div class="foot-text">
          copyright &copy;{{ new Date().getFullYear() }} Tark Audio
        </div>
      </div>

      <ul class="social-icon-container">
        <li class="social-icon">
          <a href="https://www.instagram.com/tarkaudio" target="_blank" rel="noopener noreferrer">
            <img class="social-icon-image" src="@/assets/socialIcons/instagram_light.svg" />
          </a>
        </li>
        <!-- <li class="social-icon">
        <a href="https://www.tiktok.com/@tarkaudio?_t=8VQW0BfmP4U&_r=1" target="_blank" rel="noopener noreferrer">
          <img class="social-icon-image" src="@/assets/socialIcons/tiktok_light.svg"/>
        </a>
      </li>
      <li class="social-icon">
        <a href="https://www.twitter.com/tarkaudio" target="_blank" rel="noopener noreferrer">
          <img class="social-icon-image" src="@/assets/socialIcons/twitter_light.svg"/>
        </a>
      </li> -->
        <li class="social-icon">
          <a href="https://www.youtube.com/channel/UCFuSzhWicuT-dI5X7Qr0Oew" target="_blank" rel="noopener noreferrer">
            <img class="social-icon-image" src="@/assets/socialIcons/youtube_light.svg" />
          </a>
        </li>
        <!-- <li class="social-icon">
        <a href="https://www.reddit.com/u/tarkaudio" target="_blank" rel="noopener noreferrer">
          <img class="social-icon-image" src="@/assets/socialIcons/reddit_light.svg"/>
        </a>
      </li> -->
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Footer'
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";

.foot {
  max-width: 1200px;
  margin: 0 auto;
}

.foot-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.foot-text-container {
  padding: 10px;
}

.foot-text {
  font-size: $text-size--sm;
  color: $color--grey5;
  text-align: left;
}

a {
  font-size: $text-size--sm;
  color: $color--white;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.social-icon-container {
  display: flex;
  flex-direction: row;
}

.social-icon {
  width: 50px;
  height: 50px;
  margin: 15px;
}

.social-icon-image {
  width: 100%;
  opacity: 80%;
}

@media only screen and (max-width: $bkpt--sm) {
  .foot-container {
    flex-direction: column-reverse;
  }

  .social-icon-container {
    justify-content: flex-end;
  }

  .social-icon {
    width: 35px;
    height: 35px;
    margin: 10px;
  }

  .foot-text {
    text-align: right;
  }
}
</style>