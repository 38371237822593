export function defineSketch(x) {

  return (p) => {
    p.setup = () => {
      x.setup(p)
    }

    p.draw = () => {
      x.draw(p)
    }
  }
}