<template>
  <RegularPage>
    <div class="thank-you">Thank you for your purchase!</div>
    <div class="message">You will be re-routed to Home in {{secondsLeft}} seconds...</div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'

export default {
  name: 'StripePaymentThankYou',
  components: {
    RegularPage
  },
  data() {
    return {
      secondsLeft: 5
    }
  },
  mounted() {
    var intervalId = setInterval( () => {
      if( this.secondsLeft-- === 1 ) {
        clearInterval(intervalId)
        this.$router.push('/home')
      }
    }, 1000)
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";
.thank-you {
  font-size: $text-size--xl;
  margin: 20px;
}

.message {
  font-size: $text-size--md;
  margin: 40px;
}
</style>