<template>
  <RegularPage>
    <ProductPageShowcase :productName="name" :userManualPath="`${userManualPath}`" :quickSpecs="[
      { key: 'TYPE:', value: 'Overdrive' },
      { key: 'DIMS:', value: '2.7x5x2.3in' },
      { key: 'POWER:', value: '+9 to +18 VDC' },
      { key: 'CURRENT:', value: '15mA' }
    ]" />

    <!-- BEGIN sections -->
    <div class="sections">

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">VIDEOS</div>
        <ProductPageVideoTabs :videos="[
          { title: 'Bass Stuff', id: 'OtWd3vtiQuE' },
          { title: 'Boring Gear Reviews', id: '53ysE3Bu2zI' },
          { title: 'Pedal Experiments', id: 'b8DpX6LRVOc' },
          { title: 'Pair of Pandas', id: 'hr9aHx93-Jk' }
        ]" />
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SAMPLES</div>
        <div class="section-content ta-section-content">
          <div class="samples-note-container">
            <div class="samples-note">Note: Growl Bass samples need a system with good low end to get a clear image of the
              sound.</div>
          </div>
          <ProductPageSampleScope :samples="[
            { desc: 'Bite Rotation', path: `${publicPath}static/products/worf/audio/bite_rotation.wav` },
            { desc: 'Bite 0%', path: `${publicPath}static/products/worf/audio/bite_0.wav` },
            { desc: 'Bite 50%', path: `${publicPath}static/products/worf/audio/bite_50.wav` },
            { desc: 'Bite 100%', path: `${publicPath}static/products/worf/audio/bite_100.wav` },
            { desc: 'Growl Rotation', path: `${publicPath}static/products/worf/audio/growl_rotation.wav` },
            { desc: 'Growl 0%', path: `${publicPath}static/products/worf/audio/growl_0.wav` },
            { desc: 'Growl 50%', path: `${publicPath}static/products/worf/audio/growl_50.wav` },
            { desc: 'Growl 100%', path: `${publicPath}static/products/worf/audio/growl_100.wav` }
          ]" />
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">DESCRIPTION</div>
        <div class="section-content long-description ta-section-content">
          WORF is a high gain op-amp overdrive with multiple clipping stages and two tone
          paths. The Drive knob sets the overdrive amount and has a very wide control range.
          The Tone switch selects between thick low-end or driving low-mids. The Volume knob
          can be used to boost or cut the volume after the drive is applied.<br /><br />

          The Growl tone path is tailored to enhance the low end of a bass. Increasing the Drive
          knob adds clarity and compression while increasing the power in the bass frequencies.<br /><br />

          The Bite tone path is designed to give more punch to the low-mids and helps to push a
          guitar amp in the middle of its frequency range.<br /><br />

          WORF has buffered input and outputs and a mechanical True-Bypass footswitch, so
          it works the same at any position in the pedal chain and does not effect the over-all
          tone when bypassed. It can be powered by a +9V to +18V DC, center-negative power
          supply and consumes a maximum of 15mA.
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SPECS</div>
        <div class="section-content ta-section-content">
          <div class="specs">
            <div class="specs-key">Operating Voltage:</div>
            <div class="specs-value">+9VDC to +18VDC</div>
            <div class="specs-key">Current Draw:</div>
            <div class="specs-value">15mA (max)</div>
            <div class="specs-key">Input Impedance:</div>
            <div class="specs-value">1 MOhm</div>
            <div class="specs-key">Output Impedance:</div>
            <div class="specs-value">100 Ohm</div>
            <div class="specs-key">Bypass:</div>
            <div class="specs-value">Mech. True-Bypass</div>
            <div class="specs-key">Dimensions:</div>
            <div class="specs-value">2.7” x 5” x 2.3”</div>
            <div class="specs-key">Weight:</div>
            <div class="specs-value">277grams</div>
            <div class="specs-key">Jack Position:</div>
            <div class="specs-value">Top</div>
          </div>
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">RESOURCES</div>
        <div class="section-content ta-section-context">
          <a class="productpage-user-manual" :href="`${userManualPath}`" target="_blank">User
            Manual</a>
        </div>
      </div>

      <!-- END sections -->
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import ProductPageShowcase from '@/components/ProductPageShowcase.vue'
import ProductPageSampleScope from '@/components/ProductPageSampleScope.vue'
import ProductPageVideoTabs from '@/components/ProductPageVideoTabs.vue'

import { getStaticProductDetails } from '@/js/StaticProductDetails.js'


export default {
  name: 'ProductPage_Worf',
  components: {
    RegularPage,
    ProductPageShowcase,
    ProductPageSampleScope,
    ProductPageVideoTabs
  },
  data() {
    return {
      name: 'worf',
      publicPath: process.env.BASE_URL,
      userManualPath: null
    }
  },
  created() {
    this.userManualPath = `${this.publicPath}${getStaticProductDetails(this.name).userManual}`
  }
}
</script>


<style lang="scss">
@import "@/styles/productPageStyles.scss";

.samples-note-container {
  text-justify: center;
  justify-content: center;
}

.samples-note {
  font-size: $text-size--sm;
  margin: auto;
  color: $color--grey3;
}
</style>