import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import VueYtframe from "vue3-ytframe"

import * as Tone from 'tone'
import { unmute } from './js/thirdparty/unmute/unmute.js'

// Create an AudioContext, unmute it, and pass it to Tone.js
let context = new AudioContext({ latencyHint: "interactive" })
let allowBackgroundPlayback = true; // default false, recommended false
let forceIOSBehavior = false; // default false, recommended false
unmute(context, allowBackgroundPlayback, forceIOSBehavior);
Tone.setContext(context)

const app = createApp(App)
app.use(router)
app.use(VueYtframe)
app.mount("#app");