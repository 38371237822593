import { dfu } from "./dfu"
import { dfuse } from "./dfuse"
import { saveAs } from "./FileSaver"
import { EventEmitter } from "events";

let vid = 0x0483;
let transferSize = 1024;
let manifestationTolerant = true;
var device = null;

// Wrapper class for ease of use
export const dfuUtil = new class DfuUtil extends EventEmitter {
  constructor() {
    super()

    this.searchTimer = null
  }

  async startDeviceSearch() {
    if( this.searchTimer ) {
      return;
    }

    this.emit("status", 'searching')
    this.emit("logInfo", 'searching for devices...')

    this.searchTimer = setInterval(async () => {
      dfu.findAllDfuInterfaces().then(
        async dfu_devices => {
          let matching_devices = [];
          for (let dfu_device of dfu_devices) {
            if (dfu_device.device_.vendorId == vid) {
              matching_devices.push(dfu_device);
            }
          }

          if (matching_devices.length == 0) {
            this.emit("status", 'searching')

          } else {
            this.emit("status", 'deviceFound')
            this.stopDeviceSearch()
          }
        }
      );
    }, 500);
  }

  async stopDeviceSearch() {
    if (this.searchTimer) {
      clearInterval(this.searchTimer)
      this.searchTimer = null
      this.emit("logInfo", 'stoping device search...')
    }
  }

  async connect() {
    await connectButton()
  }

  async updateDevice(fwImage) {
    await downloadButton(fwImage)
  }

  async readFromDevice() {
    await uploadButton()
  }
}

function hex4(n) {
  let s = n.toString(16)
  while (s.length < 4) {
    s = '0' + s;
  }
  return s;
}

function hexAddr8(n) {
  let s = n.toString(16)
  while (s.length < 8) {
    s = '0' + s;
  }
  return "0x" + s;
}

function niceSize(n) {
  const gigabyte = 1024 * 1024 * 1024;
  const megabyte = 1024 * 1024;
  const kilobyte = 1024;
  if (n >= gigabyte) {
    return n / gigabyte + "GiB";
  } else if (n >= megabyte) {
    return n / megabyte + "MiB";
  } else if (n >= kilobyte) {
    return n / kilobyte + "KiB";
  } else {
    return n + "B";
  }
}

async function fixInterfaceNames(device_, interfaces) {
  // Check if any interface names were not read correctly
  if (interfaces.some(intf => (intf.name == null))) {
    // Manually retrieve the interface name string descriptors
    let tempDevice = new dfu.Device(device_, interfaces[0]);
    await tempDevice.device_.open();
    await tempDevice.device_.selectConfiguration(1);
    let mapping = await tempDevice.readInterfaceNames();
    await tempDevice.close();

    for (let intf of interfaces) {
      if (intf.name === null) {
        let configIndex = intf.configuration.configurationValue;
        let intfNumber = intf["interface"].interfaceNumber;
        let alt = intf.alternate.alternateSetting;
        intf.name = mapping[configIndex][intfNumber][alt];
      }
    }
  }
}

function getDFUDescriptorProperties(device) {
  // Attempt to read the DFU functional descriptor
  // TODO: read the selected configuration's descriptor
  return device.readConfigurationDescriptor(0).then(
    data => {
      let configDesc = dfu.parseConfigurationDescriptor(data);
      let funcDesc = null;
      let configValue = device.settings.configuration.configurationValue;
      if (configDesc.bConfigurationValue == configValue) {
        for (let desc of configDesc.descriptors) {
          if (desc.bDescriptorType == 0x21 && desc.hasOwnProperty("bcdDFUVersion")) {
            funcDesc = desc;
            break;
          }
        }
      }

      if (funcDesc) {
        return {
          WillDetach: ((funcDesc.bmAttributes & 0x08) != 0),
          ManifestationTolerant: ((funcDesc.bmAttributes & 0x04) != 0),
          CanUpload: ((funcDesc.bmAttributes & 0x02) != 0),
          CanDnload: ((funcDesc.bmAttributes & 0x01) != 0),
          TransferSize: funcDesc.wTransferSize,
          DetachTimeOut: funcDesc.wDetachTimeOut,
          DFUVersion: funcDesc.bcdDFUVersion
        };
      } else {
        return {};
      }
    },
    error => { }
  );
}

function logDebug(msg) { dfuUtil.emit("logDebug", msg); }
function logInfo(msg) { dfuUtil.emit("logInfo", msg); }
function logWarning(msg) { dfuUtil.emit("logWarning", msg); }
function logError(msg) { dfuUtil.emit("logError", msg); }
function logProgress(stage, done, total) { dfuUtil.emit("logProgress", stage, done, total) }

function onDisconnect(reason) {
  if (reason) {
    logInfo(reason)
  }

  dfuUtil.emit("status", 'disconnected')
}

function onUnexpectedDisconnect(event) {
  if (device !== null && device.device_ !== null) {
    if (device.device_ === event.device) {
      device.disconnected = true;
      device = null;
    }
  }

  onDisconnect("Device disconnected");
}

async function connect(device) {
  try {
    await device.open();
  } catch (error) {
    onDisconnect(error);
    logError(error)
  }

  // Attempt to parse the DFU functional descriptor
  let desc = {};
  try {
    desc = await getDFUDescriptorProperties(device);
  } catch (error) {
    onDisconnect(error);
    throw error;
  }

  let memorySummary = "";
  if (desc && Object.keys(desc).length > 0) {
    device.properties = desc;
    let info = `WillDetach=${desc.WillDetach}, ManifestationTolerant=${desc.ManifestationTolerant}, CanUpload=${desc.CanUpload}, CanDnload=${desc.CanDnload}, TransferSize=${desc.TransferSize}, DetachTimeOut=${desc.DetachTimeOut}, Version=${hex4(desc.DFUVersion)}`;
    logInfo(info)
    transferSize = desc.TransferSize;
    if (desc.CanDnload) {
      manifestationTolerant = desc.ManifestationTolerant;
    }

    if (desc.DFUVersion == 0x011a && device.settings.alternate.interfaceProtocol == 0x02) {
      device = new dfuse.Device(device.device_, device.settings);
      if (device.memoryInfo) {
        let totalSize = 0;
        for (let segment of device.memoryInfo.segments) {
          totalSize += segment.end - segment.start;
        }
        memorySummary = `Selected memory region: ${device.memoryInfo.name} (${niceSize(totalSize)})`;
        for (let segment of device.memoryInfo.segments) {
          let properties = [];
          if (segment.readable) {
            properties.push("readable");
          }
          if (segment.erasable) {
            properties.push("erasable");
          }
          if (segment.writable) {
            properties.push("writable");
          }
          let propertySummary = properties.join(", ");
          if (!propertySummary) {
            propertySummary = "inaccessible";
          }

          memorySummary += `\n${hexAddr8(segment.start)}-${hexAddr8(segment.end - 1)} (${propertySummary})`;
        }
      }
    }
  }

  // Bind logging methods
  device.logDebug = logDebug;
  device.logInfo = logInfo;
  device.logWarning = logWarning;
  device.logError = logError;
  device.logProgress = logProgress;

  // Display basic dfu-util style info
  let segment = device.getFirstWritableSegment();
  if (segment) {
    if (segment.start === 0x90000000)
      segment.start += 0x40000
    device.startAddress = segment.start;
  }

  return device;
}

async function connectButton() {
  if (device) {
    device.close().then(onDisconnect);
    device = null;
  } else {
    let filters = [];
    if (vid) {
      filters.push({ 'vendorId': vid });
    }
    navigator.usb.requestDevice({ 'filters': filters }).then(
      async selectedDevice => {
        let interfaces = dfu.findDeviceDfuInterfaces(selectedDevice);
        if (interfaces.length == 0) {
          logInfo(selectedDevice);
          logInfo("The selected device does not have any USB DFU interfaces.");
        } else if (interfaces.length == 1) {
          await fixInterfaceNames(selectedDevice, interfaces);
          device = await connect(new dfu.Device(selectedDevice, interfaces[0]));
          app.no_device = false;
        } else {
          await fixInterfaceNames(selectedDevice, interfaces);
          async function connectToSelectedInterface() {
            let filteredInterfaceList = interfaces.filter(ifc => ifc.name.includes("0x08000000"))
            if (filteredInterfaceList.length === 0) {
              logError("No interace with flash address 0x08000000 found.")
              logError("The selected device does not have a Flash Memory sectiona at address 0x08000000.");
            } else {
              device = await connect(new dfu.Device(selectedDevice, filteredInterfaceList[0]));
              dfuUtil.emit("status", 'connected')
              dfuUtil.emit("logProgress", 'erase', 0, 100);
              dfuUtil.emit("logProgress", 'write', 0, 100);
            }
          }
          await connectToSelectedInterface();
        }
      }
    ).catch(error => {
      logError(error);
    });
  }
}

async function uploadButton() {
  if (!device || !device.device_.opened) {
    onDisconnect();
    device = null;
  } else {
    try {
      let status = await device.getStatus();
      if (status.state == dfu.dfuERROR) {
        await device.clearStatus();
      }
    } catch (error) {
      device.logWarning("Failed to clear status");
    }

    // TODO: this should not be hard coded
    let maxSize = 131072;//Infinity;
    // if (!dfuseUploadSizeField.disabled) {
    // maxSize = parseInt(dfuseUploadSizeField.value);
    // }

    try {
      const blob = await device.do_upload(transferSize, maxSize);
      saveAs(blob, "firmware.bin");
    } catch (error) {
      logError(error);
    }
  }

  return false;
}

async function downloadButton(fwImage) {
  if (device && fwImage != null) {
    try {
      let status = await device.getStatus();
      if (status.state == dfu.dfuERROR) {
        await device.clearStatus();
      }
    } catch (error) {
      device.logWarning("Failed to clear status");
    }

    dfuUtil.emit("status", 'updating')
    await device.do_download(transferSize, fwImage, manifestationTolerant).then(
      () => {
        logInfo("Done!");
        dfuUtil.emit("status", 'done');
        if (!manifestationTolerant) {
          device.waitDisconnected(5000).then(
            dev => {
              onDisconnect();
              device = null;
            },
            error => {
              // It didn't reset and disconnect for some reason...
              logError("Device unexpectedly tolerated manifestation.");
            }
          );
        }
      },
      error => {
        dfuUtil.emit("status", 'downloadError')
        logError(error);
      }
    )
  }
}

// Check if WebUSB is available
if (typeof navigator.usb !== 'undefined') {
  navigator.usb.addEventListener("disconnect", onUnexpectedDisconnect);

} else {
  logError('WebUSB not available.')
}
