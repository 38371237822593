<template>
  <RegularPage>
    <div class="contactus-title">
      Contact Us
    </div>
    <div v-if="formSubmitted" class="contactus-content">
      Thank you for your inquiry! We will get back to you soon
    </div>
    <div v-else class="contactus-content">
      <form @submit.prevent="submitForm">
        <div class="contactus-context">
          <div class="contactus-context-entry">
            <label for="name">Name:</label>
            <input class="ta-input-text contactus-input-text" type="text" id="name" v-model="formData.name" required>
          </div>

          <div class="contactus-context-entry">
            <label for="email">Email:</label>
            <input class="ta-input-text contactus-input-text" type="email" id="email" v-model="formData.email" required>
          </div>

          <div class="contactus-context-entry">
            <label for="contactus-dropdown">Category:</label>
            <select class="contactus-select-drowdown" v-model="formData.category" id="contactus-dropdown">
              <option class="contactus-dropdown-item" v-for="category in categories" :value="category">{{ category }}
              </option>
            </select>
          </div>

        </div>

        <div class="contactus-message">
          <label for="message">Message:</label>
          <textarea class="ta-input-text contactus-input-text contactus-input-message" id="message"
            v-model="formData.message" required></textarea>
        </div>

        <div class="contactus-button-container">
          <button class="ta-button" style="height: 60px;" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </RegularPage>
</template>


<script>
import { firebaseHelper } from '@/js/FirebaseHelper'

import RegularPage from '@/components/RegularPage.vue'

export default {
  name: 'Contactus',
  components: {
    RegularPage
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        category: '',
        message: ''
      },
      formSubmitted: false,
      categories: [
        "General Inquiry",
        "Repair Request",
        "Other"
      ]
    };
  },
  mounted() {
    this.formSubmitted = false
    this.formData.category = this.categories[0]
  },
  methods: {
    async submitForm() {
      const handleContactUsSubmit = firebaseHelper.getHttpsCallableFunction('handleContactUsSubmit')
      const response = await handleContactUsSubmit({ formData: this.formData })

      this.formSubmitted = true
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/styles/style.scss";

.contactus-title {
  font-size: $text-size--xxl;
  text-align: center;
}

.contactus-content {
  margin: auto;
  padding: 20px;
  max-width: 800px;
}

.contactus-input-text {
  text-align: left;
  background-color: $color--grey1;
  margin: 10px;
  padding: 0 10px 0 10px;
}

#contactus-dropdown {
  font-size: $text-size--lg;
  color: black;
  background-color: $color--grey1;
  margin: 10px;
  padding: 10px 0 10px 0;

  border: 4px;
  border-color: $color--cyan;
  border-style: solid;
  border-radius: 10px;
}

.contactus-dropdown-item {
  font-size: $text-size--md;
  padding: 20px;
  color: black;
}

.contactus-dropdown-label {
  font-size: $text-size--lg;
  font-weight: bold;
}

.contactus-context-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 500px;
}

.contactus-select-dropdown {
  text-align: center;
}

.contactus-input-message {
  height: 200px;
}

.contactus-button-container {
  text-align: center;
  margin: 10px;
}
</style>