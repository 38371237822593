<template>
  <transition name="modal-animation">
    <div v-show="active" class="modal" @click="this.$emit('close')">
      <transition name="modal-animation-inner">
        <div v-show="active" class="modal-inner" :style="`width: ${this.width}; max-width: ${this.maxWidth};`" @click.stop="">
          <div class="modal-header">
            <i class="fa-solid fa-x fa-xl" @click="this.$emit('close')"/>
          </div>
          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>


<script>
export default {
  name: 'Modal',
  props: {
    active: Boolean,
    width: String,
    maxWidth: String
  },
  methods: {
    close() {
      console.log('modal: close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";

.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.6s ease-in-out;
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active,
.modal-animation-inner-leave-active {
  transition: all 0.6s ease-in-out;
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0);
}
.modal-animation-inner-leave-to {
  opacity: 0;
  transform: scale(5);
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $modal--page-opacity;
}

.modal-inner {
  position: relative;
  background-color: $mobal--background-color;
  padding: 10px;
  margin: auto;
  border-radius: 10px;
}

.modal-header {
  height: 60px;
  margin: auto;
  justify-content: right;
  border: none;
}
</style>