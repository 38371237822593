import { initializeApp, getApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getFirestore, collection, getDocs, getDoc, doc } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions"

import { EventEmitter } from 'events'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID
}

export const firebaseHelper = new class FirebaseHelper extends EventEmitter {
  constructor() {
    super()
    initializeApp(firebaseConfig)
    this.db = getFirestore()
    this.storage = getStorage()
    this.analytics = getAnalytics()

    this.analyticsEnabled = false

    // NOTE: un-comment for local testing of cloud functions using 'firebase emulators:start --only functions'
    // connectFunctionsEmulator(getFunctions(getApp()), '127.0.0.1', 5001)
  }

  async getDeviceData(deviceName) {
    const docRef = doc(this.db, `device-data/${deviceName}`);
    const snapshot = await getDoc(docRef);
    return snapshot.data()
  }

  async getDeviceStock(deviceName, variantId) {
    const docRef = doc(this.db, `device-data/${deviceName}/variants/${variantId}`);
    const snapshot = await getDoc(docRef);
    return snapshot.data().stock
  }

  async getFwImagePath(deviceName) {
    try {
      const docRef = doc(this.db, `device-data/${deviceName}`);
      const snapshot = await getDoc(docRef);
      return snapshot.get('fwImagePath');
    } catch (error) {
      console.error('Error getting fwImagePath data:', error);
    }
  }

  async getFwImage(imagePath) {
    let url = await this.getStorageUrl(imagePath)

    fetch(url).then(res => res.arrayBuffer()).then(buffer => {
      this.emit("imageDownloaded", buffer)
    })
  }

  async getStorageUrl(fileName) { return await getDownloadURL(ref(this.storage, fileName)) }

  getHttpsCallableFunction(fname) { return httpsCallable(getFunctions(getApp()), fname); }

  logAnalytic(eventName, params, opt) {
    if (this.analyticsEnabled) {
      logEvent(this.analytics, eventName, params, opt);
    }
  }

  enableAnalytics() { this.analyticsEnabled = true; }

  disableAnalytics() { this.analyticsEnabled = false; }
}