<template>
  <div class="button-scope">
    <div class="button-grid">
      <div class="labeled-button" v-for="(sample, i) in samples" :key="i">
        <div class="clip-description">{{ sample.desc }}</div>
        <button class="ta-button play-button" @click="onPlayClick(i)">play</button>
      </div>
    </div>
    <div class="scope-stop-container">
      <div class="productOscopeCanvas" id="productSampleScope-oscopeCanvas" />
      <button class="ta-button play-button clear" @click="onClearClick()">stop</button>
    </div>
  </div>
</template>


<script>
import * as Tone from 'tone'
import { OscopeClass } from '@/js/Oscope.js'

export default {
  name: 'ProductSampleScope',

  props: {
    samples: Array,
    canvasId: String
  },

  async mounted() {
    window.addEventListener('resize', this.windowResize)

    this.players = []
    for (const sample of this.samples) {
      this.players.push(new Tone.Player(sample.path).toDestination())
    }

    this.waveform = new Tone.Waveform()
    this.players[0].connect(this.waveform)

    var dims = this.calcOscopeSize(document.documentElement.clientWidth)
    this.oscope = new OscopeClass(
      'productSampleScope-oscopeCanvas',
      dims.width,
      dims.height,
      { r: 0, g: 255, b: 0 },
      { r: 75, g: 75, b: 75 },
      this.waveform)
  },

  unmounted() {
    window.removeEventListener('resize', this.windowResize)
    this.stopAllPlayers()
    this.oscope.cleanup()
  },

  methods: {
    windowResize() {
      var width = document.documentElement.clientWidth;
      this.oscope.resize(this.calcOscopeSize(width))
    },

    calcOscopeSize(_width) {
      var newWidth = 500
      var newHeight = 320

      if (_width < 320) { // bkpt--xxs
        newWidth = 220
        newHeight = 200
      }
      else if (_width < 480) { // bkpt--xs
        newWidth = 260
        newHeight = 220
      }
      else if (_width < 576) { // bkpt--sm
        newWidth = 400
      }
      else if (_width < 768) { // bkpt--md
        newWidth = 420
      }
      else if (_width < 992) { // bkpt--lg
        newWidth = 420
      }

      return { width: newWidth, height: newHeight }
    },

    async onPlayClick(clipNum) {
      await Tone.start()
      this.stopAllPlayers()
      this.players[clipNum].connect(this.waveform)
      this.players[clipNum].start()
    },

    onClearClick() {
      this.stopAllPlayers();
    },

    stopAllPlayers() {
      for (const player of this.players) {
        player.stop()
      }
    }
  }
}
</script>


<style lang="scss">
@import "@/styles/productPageStyles.scss";

.button-scope {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.button-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-basis: 40%;
}

.labeled-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 10px;
}

.clip-description {
  flex-basis: 30%;
  align-items: center;
  margin: 5px;
  pad: 0;
  font-size: 20px;

  text-align: center;
  font-weight: bold;
}

.play-button {
  flex-basis: 35%;
  margin: 5px;
  pad: 0;
  font-size: 20px;
  vertical-align: middle;

  text-align: center;
  max-height: 50px;
}

.scope-stop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.play-button.clear {
  background-color: red;
  color: $color--white;
  max-width: 100px;
}

.productOscopeCanvas {
  border: 2px;
  border-color: $color--grey5;
  border-style: solid;
  border-radius: 5px;
  height: 320px;
  width: 500px;
  margin: 20px auto;
  overflow: hidden;
  box-shadow: inset 0 0 40px rgba(64, 64, 0, 0.5);
}

@media only screen and (max-width: $bkpt--lg) {
  .productOscopeCanvas {
    width: 420px;
    height: 320px;
  }
}

@media only screen and (max-width: $bkpt--md) {
  .button-scope {
    flex-direction: column;
  }

  .productOscopeCanvas {
    width: 420px;
    height: 320px;
  }
}

@media only screen and (max-width: $bkpt--sm) {
  .productOscopeCanvas {
    width: 400px;
    height: 320px;
  }
}

@media only screen and (max-width: $bkpt--xs) {
  .productOscopeCanvas {
    width: 260px;
    height: 220px;
  }
}

@media only screen and (max-width: $bkpt--xxs) {
  .productOscopeCanvas {
    width: 220px;
    height: 200px;
  }
}
</style>