<template>
  <RegularPage>
    <div class="title">Payment Incomplete</div>
    <div class="message">An error occured during the payment process.</div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'

export default {
  name: 'StripePaymentIncomplete',
  components: {
    RegularPage
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";
.title {
  font-size: $text-size--xl;
  margin: 20px;
}

.message {
  font-size: $text-size--md;
  margin: 40px;
}
</style>