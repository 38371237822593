<template>
  <RegularPage>
    <ProductPageShowcase :productName="name" :userManualPath="`${userManualPath}`" :quickSpecs="[
      { key: 'TYPE:', value: 'Fuzz/Other' },
      { key: 'DIMS:', value: '3.7 x 2.7 x 2 in' },
      { key: 'POWER:', value: '+9 to +18 VDC' },
      { key: 'CURRENT:', value: '40mA' }
    ]"
    />

    <!-- BEGIN sections -->
    <div class="sections">

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">VIDEOS</div>
        <ProductPageVideoTabs :videos="[
          { title: 'Boring Gear Reviews', id: '3T1-ja8aj1Y' },
          { title: 'Pedal Experiments', id: 'KXLcclrdmQs' },  
          { title: 'Pair of Pandas', id: 'BOTi7vlFfRY' }
        ]" />
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SAMPLES</div>
        <div class="section-content ta-section-content">
          <ProductPageSampleScope :samples="[
            { desc: 'Fuzz/ Oct+2', path: `${publicPath}static/products/squidink/audio/squidink_clip1.wav` },
            { desc: '100% Fuzz', path: `${publicPath}static/products/squidink/audio/squidink_clip2.wav` },
            { desc: 'Oct Wiggle', path: `${publicPath}static/products/squidink/audio/squidink_clip3.wav` },
            { desc: 'Oct Splatter', path: `${publicPath}static/products/squidink/audio/squidink_clip4.wav` }
          ]" />
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">DESCRIPTION</div>
        <div class="section-content long-description ta-section-content">
          <div class="section-content name">SQUIDINK</div> is a blend of a Fuzz and an analog Multi-Octave Generator. The
          Fuzz circuit employs a classic “Fuzz Face” topology using modern, low-gain Silicon PNP transistors. The <div
            class="section-content emph">FUZZ</div> knob adjusts the gain of the fuzz circuit. The <div
            class="section-content emph">MIX</div> knob controls the ratio of fuzz and octave signals. The <div
            class="section-content emph">VOLUME</div> knob controls the output volume of the effect.<br /><br />
          The analog Multi-Octave Generator uses a Phase-Locked Loop (PLL) circuit to output signals that are -2, -1, +1,
          and +2 octaves away from the input signal. The low and high octave selection switches select between the -2/-1,
          and +1/+2 octave sources, respectively. The <div class="section-content emph">OCT MIX</div> knob controls the
          mix ratio between the high and low octave sources. The mixed octave signal is fed through a Voltage Controlled
          Amplifier (VCA) that is modulated by the envelope of the input signal. This amplitude shaping helps to add
          articulation to the octave signal.<br /><br />
          The analog Multi-Octave circuit has multiple tracking options. In <div class="section-content emph">NORMAL</div>
          mode, the <div class="section-content emph">TRACK</div> knob and <div class="section-content emph">SLOW/FAST
          </div> switch control the speed and character of the octave tracking. In <div class="section-content emph">
            WIGGLE</div> mode, the octave signal portamentos to the target output frequency. The portamento time is
          controlled by the <div class="section-content emph">TRACK</div> knob and <div class="section-content emph">
            SLOW/FAST</div> switch. The octave signal can be enabled and disabled with the <div
            class="section-content emph">OCT</div> footswitch. When the octave circuity is disabled, the <div
            class="section-content emph">MIX</div> knob has no effect on the fuzz signal.<br /><br />
          <div class="section-content emph">SQUIDINK</div> has a high-impedance input buffer and a low-impedance
          output. It implements relay controlled True-Bypass. It can be powered by a +9V to +18V, DC, center-negative
          voltage source and it consumes a maximum power supply current of 40mA.
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SPECS</div>
        <div class="section-content ta-section-content">
          <div class="specs">
            <div class="specs-key">Operating Voltage:</div>
            <div class="specs-value">+9VDC to +18VDC</div>
            <div class="specs-key">Current Draw:</div>
            <div class="specs-value">40mA (max)</div>
            <div class="specs-key">Input Impedance:</div>
            <div class="specs-value">1 MOhm</div>
            <div class="specs-key">Output Impedance:</div>
            <div class="specs-value">5.1 kOhm (max)</div>
            <div class="specs-key">Bypass:</div>
            <div class="specs-value">Relay True-Bypass</div>
            <div class="specs-key">Dimensions:</div>
            <div class="specs-value">3.7" x 4.7" x 2"</div>
            <div class="specs-key">Weight:</div>
            <div class="specs-value">343 grams</div>
            <div class="specs-key">Jack Position:</div>
            <div class="specs-value">Top</div>
          </div>
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">RESOURCES</div>
        <div class="section-content ta-section-context">
          <a class="productpage-user-manual" :href="`${userManualPath}`" target="_blank">User
            Manual</a>
        </div>
      </div>

      <!-- END sections -->
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import ProductPageShowcase from '@/components/ProductPageShowcase.vue'
import ProductPageSampleScope from '@/components/ProductPageSampleScope.vue'
import ProductPageVideoTabs from '@/components/ProductPageVideoTabs.vue'

import { getStaticProductDetails } from '@/js/StaticProductDetails.js'


export default {
  name: 'ProductPage_SquidInk',
  components: {
    RegularPage,
    ProductPageShowcase,
    ProductPageSampleScope,
    ProductPageVideoTabs
  },
  data() {
    return {
      name: 'squidink',
      publicPath: process.env.BASE_URL,
      userManualPath: null
    }
  },
  created() {
    this.userManualPath = `${this.publicPath}${getStaticProductDetails(this.name).userManual}`
  }
}
</script>


<style lang="scss">
@import "@/styles/productPageStyles.scss";
</style>