<template>
  <div class="showcase">
    <div class="showcase-row">
      <div class="showcase-header">
        <div class="showcase-title">{{ getProductData('title') }}</div>
        <div class="showcase-short-description">{{ getProductData('shortDescription') }}</div>
      </div>
      <div class="showcase-abstract" v-if="getProductData('abstractShape')"><img style="width: 100%;"
          :src="getAssetUrl(getProductData('abstractShape'))" />
      </div>
    </div>
    
    <div class="showcase-row">
      <div class="showcase-image">
        <Carousel :images="getProductData('carouselImages')" />
      </div>

      <div class="showcase-info">
        <div v-if="variants.length > 1" class="showcase-variant-select">
          <label class="showcase-variant-dropdown-label" for="showcase-variant-dropdown">Select a Color:</label>
          <select class="showcase-variant-select-dropdown" v-model="selectedVariant" id="showcase-variant-dropdown" @change="handleVariantSelect()">
              <option class="showcase-variant-dropdown-item" v-for="variant in variants" :value="variant.name">{{ variant.name }}
              </option>
          </select>
        </div>

        <ProductPrice :price="getProductData('price')" :salePrice="getProductData('salePrice')"
          :onSale="getProductData('onSale')" />
        <div v-if="this.productStock === 0">
          <div class="showcase-buy-button-container"><button class="ta-button"
              style="background-color: grey">Sold-Out</button></div>
          <div class="showcase-buy-button-note">Enter your email to get an in-stock notification</div>
          <div class="showcase-stock-reminder">
            <StockReminder :productName="getProductData('name')" :variantId="getProductData('variantId')" label="Notify me!" />
          </div>
        </div>
        <div v-else>
          <div class="showcase-displayNote" v-if="getProductData('displayNote')">{{ getProductData('displayNote') }}</div>
          <div class="showcase-buy-button-container">
            <div class="showcase-buy-button-container"><button class="ta-button" type="button" @click="onAddToCart">Add
                to Cart</button></div>
          </div>

          <div class="showcase-user-manual" v-if="userManualPath"><a :href="userManualPath" target="_blank">Click for User Manual</a></div>

          <div class="showcase-quickinfo-container">
            <div class="showcase-quickinfo">
              <div class="showcase-quickinfo-text">Quick Info</div>
              <div v-for="(quickSpec, i) in quickSpecs" :key="i">
                <div class="specs">
                  <div class="specs-key">{{ quickSpec.key }}</div>
                  <div class="specs-value">{{ quickSpec.value }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { cartManager } from '@/js/CartManager.js'
import { Product, getProductVariants } from '@/js/ProductHelper.js'
import { firebaseHelper } from '@/js/FirebaseHelper.js'

import Carousel from '@/components/Carousel.vue'
import StockReminder from '@/components/StockReminder.vue'
import ProductPrice from "@/components/ProductPrice.vue"

export default {
  name: 'ProductShowcase',
  
  components: {
    Carousel,
    StockReminder,
    ProductPrice
  },

  props: {
    productName: String,
    userManualPath: String,
    quickSpecs: Array
  },

  data() {
    return {
      productStock: 1,
      variants: getProductVariants(this.productName),
      selectedVariant: '',
      vIdx: 0,
      product: null
    }
  },

  async created() {
    this.selectedVariant = this.variants[0].name
    this.handleVariantSelect()
  },

  methods: {
    getAssetUrl(img) {
      return require('@/assets/' + img)
    },

    async handleVariantSelect() {
      const variantId = this.variants.find(obj => obj.name === this.selectedVariant).id
      this.product = new Product({name: this.productName, variantId: variantId})
      this.productStock = await this.product.getStock()

      // firebaseHelper.logAnalytic('product_view_event', {
      //   event_name: 'product_view',
      //   name: [ `${this.productName}-${variantId}` ]
      // })
    },

    getProductData(dataKey) { return this.product.getData(dataKey) },

    onAddToCart() {
      cartManager.addItem(
        cartManager.createItem(
          {
            name: this.product.getName(),
            variantId: this.product.getVariantId()
          }
        )
      )

      setTimeout(() => {
        this.$router.push('/cart')
      }, 500)
    },
  }

}
</script>


<style lang="scss" scoped>
@import "@/styles/productPageStyles.scss";
.showcase {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcase-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.showcase-header {
  flex-basis: 65%;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 20px;
}

.showcase-title {
  flex-basis: 50%;
  font-size: 64px;
  font-weight: bolder;
}

.showcase-short-description {
  flex-basis: 50%;
  font-size: 32px;
  color: $color--grey2;
}

.showcase-abstract {
  flex-basis: 40%;
  align-self: center;
  padding: 20px;
}

.showcase-image {
  flex-basis: 60%;
  padding: 20px;
  align-self: center;
}

#showcase-variant-dropdown {
  font-size: $text-size--lg;
  color: black;
  margin: 10px;
  padding: 10px;

  border: 4px;
  border-color: $color--cyan;
  border-style: solid;
  border-radius: 10px;
}

.showcase-variant-dropdown-item {
  font-size: $text-size--md;
  padding: 20px;
  color: black;
}

.showcase-info {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  justify-content: center;
  font-size: 24px;
}

.showcase-variant-select {
  display: flex;
  flex-direction: column;
}

.showcase-variant-dropdown-label {
  font-size: $text-size--lg;
  font-weight: bold;
}

.showcase-variant-select-dropdown {
  text-align: center;
}

.showcase-info-sold-out {
  font-size: 28px;
  margin: 20px 0;
  font-weight: bold;
}

.showcase-info-color-select {
  font-size: 24px;
  margin: 10px 0;
}

.showcase-stock-reminder {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  max-width: 350px;
}

.showcase-displayNote {
  margin: auto;
  max-width: 200px;
}

.showcase-buy-button-container {
  margin: 20px auto;
  height: 70px;
}

.showcase-buy-button-note {
  margin: 10px auto;
  max-width: 200px;
  color: $color--grey1;
}

.showcase-user-manual {
  margin: 35px;
  color: $color--grey1;
}

.showcase-quickinfo-container {
  margin: 20px;
}

.showcase-quickinfo {
  text-align: center;
  border: 2px;
  border-color: $color--grey2;
  border-style: solid;
  border-radius: 10px;

  max-width: 400px;
  margin: auto;
}

.showcase-quickinfo-text {
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;

  border-bottom: 1px solid $color--grey2;
}


@media only screen and (max-width: $bkpt--md) {
  .showcase-row {
    flex-direction: column;
    max-width: 576px;
  }
}
</style>