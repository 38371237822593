import { getStaticProductDetails } from "@/js/StaticProductDetails.js";
import { firebaseHelper } from "@/js/FirebaseHelper.js";

export function getProductVariants(name) { return getStaticProductDetails(name).variants }

export class Product {
  constructor(opts) {
    this.name = opts.name
    this.variantId = opts.variantId

    // derived data
    this.staticDetails = getStaticProductDetails(this.name)
    this.vIdx = this.staticDetails.variants.findIndex(obj => obj.id === this.variantId)
    this.staticVariantDetails = getStaticProductDetails(this.name).variants[this.vIdx]
  }

  getName() { return this.name }

  getVariantId() { return this.variantId }

  isEqual(other) {
    if(this.name === other.name &&
      this.variantId === other.variantId)
      return true
    
    return false
  }

  getData(dataKey) {
    switch( dataKey ) {
      case 'variantId': {
        return this.variantId
      }

      case 'variantName': {
        return this.staticVariantDetails.name
      }

      case 'adjustedPrice': {
        if( this.staticVariantDetails.onSale ) {
          return this.staticVariantDetails.salePrice
        
        } else {
          return this.staticVariantDetails.price
        }
      }

      case 'priceId': {
        if( this.staticVariantDetails.onSale ) {
          return this.staticVariantDetails.salePriceId
        
        } else {
          return this.staticVariantDetails.priceId
        }
      }

      default:
        break;
    }

    if( dataKey in this.staticDetails ) {
      return this.staticDetails[dataKey]
    }

    if( dataKey in this.staticVariantDetails ) {
      return this.staticVariantDetails[dataKey]
    }
  }
  
  async getStock() { return await firebaseHelper.getDeviceStock(this.name, this.variantId) }

  // serialization
  toJSON() {
    return {
      name: this.name,
      variantId: this.variantId,
    };
  }

  // deserialization
  static fromJSON(json) {
    return new Product(json);
  }
}