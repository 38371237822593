<template>
  <form style="margin: 0;" @submit.prevent="onAddStockReminderEmail">
    <input class="ta-input-text" type="email" style="padding: 10px" v-model="stockReminderEmail"
      placeholder="your@email.com" required>
    <input class="ta-button" type="submit" style="margin: 20px" :value="label">
  </form>

  <Modal :active="this.modalActive" :width="'80%'" :maxWidth="'400px'" @close="this.modalActive = false">
    <div class="modal-content">
      <div class="modal-title">Success!</div>
      <div class="modal-message">Thank you for your interest! An email will be sent to <div class="modal-message emph">
          {{ stockReminderEmail_modal }}</div> when more <div class="modal-message emph">{{ productName.toUpperCase() }}
        </div>
        is available.</div>
    </div>
  </Modal>
</template>


<script>
import { firebaseHelper } from '@/js/FirebaseHelper.js'
import Modal from '@/components/Modal.vue'

export default {
  name: 'StockReminder',
  components: {
    Modal
  },
  props: {
    productName: String,
    variantId: String,
    label: String
  },
  data() {
    return {
      stockReminderEmail: '',
      stockReminderEmail_modal: '',
      modalActive: false,
    }
  },
  methods: {
    async onAddStockReminderEmail() {
      if (this.stockReminderEmail != '') {
        const addStockReminderEmail = firebaseHelper.getHttpsCallableFunction('addStockReminderEmail')
        addStockReminderEmail({ productName: this.productName, variantId: this.variantId, email: this.stockReminderEmail })
          .then((response) => {
            // handle success
            console.log(response)
          })
          .catch((error) => {
            // handle error
            // TODO: better error handling
            console.log(error);
          })
          .finally(() => {
            // always executed
          });

        this.stockReminderEmail_modal = this.stockReminderEmail
        this.stockReminderEmail = ''
        this.modalActive = true
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";

.modal-content {
  justify-content: center;
  text-align: center;
  margin: auto;
  border: none;
  color: $modal--font-color;
}

.modal-title {
  font-size: $text-size--lg;
  font-weight: bold;
  margin: 10px;
  color: $modal--font-color;
}

.modal-message {
  display: inline;
  font-size: $text-size--md;
  margin: 10px;
  color: $modal--font-color;
}

.modal-message.emph {
  font-weight: bold;
}
</style>