<template>
  <RegularPage>
    <!-- SHOPPING CART -->
    <div class="cart-title">Shopping Cart</div>
    <div v-if="cartEmpty()" class="cart-empty-note" :key="componentKey">cart is empty</div>
    <div v-else>
      <ul class="cart-item-list" :key="componentKey">
        <li class="cart-item" v-for="(cartItem, i) in cartManager.getItems()" :key="i">
          <CartItem
            :title="getItemData(cartItem, 'title')"
            :shortDescription="getItemData(cartItem, 'shortDescription')"
            :variantName="getItemData(cartItem, 'variantName')"
            :numVariants="getItemVariantCount(cartItem)"
            :quantity="getItemData(cartItem, 'quantity')"
            :quantityMax="getItemData(cartItem, 'quantityMax')"
            :imgSrc="getItemData(cartItem, 'cartImg')"
            :unitPrice="getItemData(cartItem, 'adjustedPrice')"
            :price="getItemData(cartItem, 'quantity') * getItemData(cartItem, 'adjustedPrice')"
            @quantityInc="updateItemQuantity(cartItem, 'inc')"
            @quantityDec="updateItemQuantity(cartItem, 'dec')"
            @remove="removeItem(cartItem)"
            />
        </li>
      </ul>

      <div class="cart-footer-container">
        <div class="cart-footer">
          <div class="cart-info">
            <div class="cart-info-key">Shipping:</div>
            <div class="cart-info-value shipping-value">FREE</div>
            <div class="cart-info-key">Taxes:</div>
            <div class="cart-info-value">TBD</div>
            <div class="cart-info-key total">Total:</div>
            <div class="cart-info-value total">${{ total }}</div>
          </div>
          <div class="cart-controls">
            <div class="cart-button-container-clear"><button type="button" class="ta-button cart-controls clear"
                @click="onClearCart">Clear</button></div>
            <div v-if="!gettingStripeLink">
              <div class="cart-button-container-checkout"><button type="button" class="ta-button cart-controls"
                  @click="onCheckout">Checkout</button></div>
            </div>
            <div v-else>
              <div class="cart-button-container-checkout"><button type="button" class="ta-button cart-controls"
                  style="background-color: grey;">Sending to Stripe...</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage'
import CartItem from '@/components/CartItem'

import { cartManager } from '@/js/CartManager.js'
import { getProductVariants } from '@/js/ProductHelper.js'

export default {
  name: 'Cart',
  components: {
    RegularPage,
    CartItem
  },

  data() {
    return {
      componentKey: 0,
      subTotal: 0,
      total: 0,
      gettingStripeLink: false,
      cartManager: cartManager
    }
  },

  mounted() {
    this.cartManager.on('updated', this.cartUpdated)
    this.calcTotal()
    this.gettingStripeLink = false;
  },

  unmounted() {
    this.cartManager.off('updated', this.cartUpdated)
  },

  methods: {
    forceRerender() {
      this.componentKey++
    },

    cartUpdated() {
      this.calcTotal()
      this.forceRerender()
    },

    calcTotal() {
      this.total = 0
      this.cartManager.getItems().forEach((item) => {
        this.total += this.getItemData(item, 'quantity') * this.getItemData(item, 'adjustedPrice')
      })
    },

    cartEmpty() {
      if (this.cartManager.getTotalNumberOfItems() > 0) return false
      return true
    },

    getItemData(item, dataKey) { return this.cartManager.getItemData(item, dataKey) },

    getItemVariantCount(item) { return getProductVariants(this.getItemData(item, 'name')).length },

    updateItemQuantity(item, dir) {
      if (dir === 'inc') this.cartManager.incrementQuantity(item)
      else if (dir === 'dec') this.cartManager.decrementQuantity(item)
      this.forceRerender()
    },

    removeItem(item) {
      this.cartManager.removeItem(item)
      this.forceRerender()
    },

    async onCheckout() {
      var allowCheckout = true;

      for (const item of this.cartManager.getItems()) {
        var stock = await this.cartManager.getItemStock(item)

        if (this.getItemData(item, 'quantity') > stock) {
          alert(`Insufficiant stock for item ${this.getItemData(item, 'name')}, variant = ${this.getItemData(item, 'variantId')}`)
          allowCheckout = false;
        }
        else {
          console.log(`sufficient stock for item ${this.getItemData(item, 'name')}, variant = ${this.getItemData(item, 'variantId')}`)
        }
      }

      if (allowCheckout) {
        console.log('checking out!')
        this.gettingStripeLink = true;
        this.cartManager.checkout(this.$router)
      }
    },

    onClearCart() {
      this.cartManager.clear()
      this.forceRerender()
    },
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";
.cart-title {
  font-size: $text-size--xxl;
  margin: 20px;
  text-align: center;
}

.cart-empty-note {
  font-size: $text-size--xl;
  color: $color--grey3;
  text-align: center;
}

.cart-item-list {
  list-style: none;
}

.cart-item {
  margin: 20px;
}

.cart-footer-container {
  margin: 40px;
}

.cart-info {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  align-items: top;
  justify-content: right;
  text-align: center;
  width: 100%;
  max-width: 275px;
  margin: 0 0 0 auto;
}

.cart-info-key {
  flex-basis: 50%;
  box-sizing: border-box;
  margin: 5px;
  pad: 0;

  text-align: right;
  font-weight: bold;

  color: $color--grey3;
  font-size: $text-size--sm;
}

.cart-info-value {
  flex-basis: 20%;
  box-sizing: border-box;
  margin: 5px;
  pad: 0;

  text-align: left;
  color: $color--grey3;
  font-size: $text-size--sm;
}

.cart-info-value.shipping-value {
  color: $color--green;
}

.cart-info-key.total {
  color: $color--white;
  font-size: $text-size--md;
}

.cart-info-value.total {
  color: $color--white;
  font-size: $text-size--md;
}

.cart-info-shipping {
  text-align: right;
  margin: 10px 0;
  color: $color--grey2;
  font-size: $text-size--sm;
}

.cart-info-total {
  text-align: right;
  margin: 10px 0;
}

.cart-controls {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.ta-button.cart-controls.clear {
  background-color: $color--red;
  filter: drop-shadow(0 0 4px $color--red);
  align-items: center;
  justify-content: center;
  max-height: 50px;
}

@media only screen and (max-width: $bkpt--sm) {
  .cart-footer-container {
    margin: 20px;
  }
}
</style>