<template>
  <div class="page-background">
    <div class="page-container">
      <slot />
    </div>
  </div>
</template>


<script>
export default {
  name: 'Page',
}
</script>


<style scoped lang="scss">
@import "@/styles/style.scss";

.page-background {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: $global-background-color;
  z-index: -1;
}

.page-container {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y:scroll;
  overflow-x:scroll;
  padding: 5px;
}
</style>