<template>
  <RegularPage>
    <div class="error">Cart Error</div>
    <div class="text">
      A cart error occured.<br>
      Don't worry, you were not charged!<br>
      Please contact us to resolve the issue.<br>
      Sorry for the trouble!
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'

export default {
  name: 'CartError',
  components: {
    RegularPage
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";
.error {
  font-size: $text-size--xxl;
  font-weight: bold;
  margin: 0 10%;
}

.text {
  font-size: $text-size--xl;
  text-align: left;
  margin: 0 10%;
}
</style>