<template>
  <nav class="navbar navbar-expand-lg">
    <div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/home">Home</router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link class="nav-link dropdown-toggle" to="/products" id="navbarDropdownMenuLink"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Products
            </router-link>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <router-link class="nav-link all-products" to="/products?category=all">ALL</router-link>

              <div v-for="category in productCategories">
                <div class="product-category">
                  <div class="product-category title">
                    <router-link :to="getProductRoute(category)">{{ category }}</router-link>
                  </div>
                  <div v-for="product in products">
                    <div v-if="product.category === category">
                      <router-link class="nav-link pedal" :to="product.route">{{ product.title }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <li class="nav-item">
          <router-link class="nav-link" to="/firmwareupdate">
            <img class="fwUpdate-icon-image" src="@/assets/icons/fwUpdate.svg" />
          </router-link>
        </li>
      </div>
    </div>
  </nav>
</template>


<script>
import { getAllStaticProductDetails } from '@/js/StaticProductDetails'

export default {
  name: 'Nav',
  components: {
  },
  data() {
    return {
      products: [],
      productCategories: []
    }
  },
  created() {
    var productDetails = getAllStaticProductDetails()
    let categories = []

    for (const [i, product] of productDetails.products.entries()) {
      if (product.display) {
        categories.push(product.category)
        this.products.push(product)
      }
    }

    this.productCategories = [...new Set(categories)]
  },
  methods: {
    getProductRoute(category) {
      return '/products?category=' + category.toLowerCase()
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";

.navbar-toggler {
  margin: 10px;
  background-color: $color--grey5;
  min-width: 90px;
  min-height: 70px;
}

.navbar-toggler-icon {
  background-color: $color--grey5;
  background-image: url('@/assets/navMenu_icon.svg');
}

.nav-item {
  list-style-type: none;
}

.nav-link {
  font-size: $text-size--lg;
  color: $link--color;
  margin: 20px;
}

.nav-link:hover {
  color: $link--color-hover !important;
}

.nav-link:active {
  color: $link--color-active;
}

.nav-link:visited {
  color: $link--color-visited;
}

.dropdown-menu {
  width: 200px;
  background-color: $color--grey1;
  border-radius: 5px;
}

.nav-link.all-products {
  font-size: $text-size--md;
  font-weight: bold;
  margin: 0 10px;
  color: $color--black;
}

.product-category {
  font-size: $text-size--md;
  padding: 0;
  margin: 10px;
  border-radius: 5px;
  border-color: $color--black;
  background-color: $color--grey4;
}

.product-category.title {
  color: $color--grey1;
  font-weight: bolder;
  font-size: $text-size--md;
}

.nav-link.pedal {
  color: $color--black;
  font-size: $text-size--md;
  font-weight: bold;
  margin: 0 20px;
}

.fwUpdate-icon-image {
  width: 100%;
  opacity: 50%;
  width: 35px;
  height: 35px;
  margin: auto;
}

a { text-decoration: none; }

@media only screen and (max-width: $bkpt--md) {
  .fwUpdate-icon-image {
    display: none;
  }
}
</style>