<template>
  <RegularPage>
    <div class="heading">Firmware Update</div>
    <div v-if="isSupportedBrowser">
      <div class="dfu-instructions">
        <div class="dfu-instructions-heading">
          <div class="dfu-instructions-heading-title">Instructions:</div>
          <div class="dfu-instructions-heading-selectedDevice">{{ selectedDevice }}</div>
        </div>
        <pre class="dfu-instructions-content">{{ instructions }}</pre>
      </div>
      <div class="dfu-content">
        <div class="dfu-device-selection" v-if="status == 'selectDevice'">
          <label class="dfu-device-dropdown-label" for="dfu-device-dropdown">Select a Device:</label>
          <select v-model="selectedDevice" id="dfu-device-dropdown" @change="handleSelectDevice()">
            <option class="dfu-device-dropdown-item" style="color: grey" disabled value>select</option>
            <option class="dfu-device-dropdown-item" v-for="product in products" :value="product.title">{{ product.title }}
            </option>
          </select>
        </div>
        <div v-else-if="status == 'deviceSelected'">
          <div class="dfu-controls">
            Retrieving firmware image from database...
          </div>
        </div>
        <div v-else-if="status == 'imageDownloaded'">
          <div class="dfu-controls">
            <button class="ta-button dfu-control" @click="handleNext()">next</button>
          </div>
        </div>
        <div v-else-if="status == 'devicePluggedIn'">
          <div class="dfu-controls">
            <button class="ta-button dfu-control" @click="handleConnect()">connect</button>
          </div>
        </div>
        <div v-else-if="status == 'connected'">
          <div class="dfu-controls">
            <button class="ta-button dfu-control" @click="handleUpdate()">update</button>
          </div>
        </div>
        <div v-else>
          <div class="dfu-controls">
          </div>
        </div>
        <div class="dfu-progress">
          <progress-bar class="dfu-progress-bar" text-fg-color="black" text-position="middle" size="huge"
            :val="eraseProgress" :text="'erase = ' + eraseProgress + '%'" />
          <progress-bar class="dfu-progress-bar" text-fg-color="black" text-position="middle" size="huge" :val="dfuProgress"
            :text="'update = ' + dfuProgress + '%'" />
        </div>
      </div>
    </div>
    <div v-else>
      Sorry, the firmware update feature is not yet supported on this browser.  Please use Chrome or Edge.
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import { getAllStaticProductDetails } from '@/js/StaticProductDetails'
import { firebaseHelper } from '@/js/FirebaseHelper'
import { dfuUtil } from '@/js/thirdparty/dfu/dfu-util.js'
import ProgressBar from '@/components/thirdparty/Progress.vue'

export default {
  name: 'FirmwareUpdate',
  components: {
    RegularPage,
    ProgressBar
  },

  data() {
    return {
      isSupportedBrowser: false,
      products: [],
      status: '',
      selectedDevice: '',
      downloadError: false,
      done: false,
      eraseProgress: 0,
      dfuProgress: 0,
      instructions: '',
      fwImage: null,
      imagePaths: {}
    }
  },

  async created() {
    this.checkBrowserCompatibility();

    const productDetails = getAllStaticProductDetails()

    for (const [i, product] of productDetails.products.entries()) {
      // get firmware image paths from firestore
      const imageLocation = await firebaseHelper.getFwImagePath(product.name)
      if( imageLocation !== undefined ) {
        this.imagePaths[product.name] = imageLocation
        this.products.push(product)
      }
    }
    
    this.checkBrowserCompatibility()
    this.handleStatus('selectDevice')
  },

  mounted() {
    dfuUtil.on("logProgress", this.onDfuLogProgress)
    dfuUtil.on('status', this.handleStatus)
    firebaseHelper.on("imageDownloaded", this.onFwImageDownloaded)
  },

  unmounted() {
    dfuUtil.off("logProgress", this.onDfuLogProgress)
    dfuUtil.off('status', this.handleStatus)
    firebaseHelper.off("imageDownloaded", this.onFwImageDownloaded)
  },

  methods: {
    checkBrowserCompatibility() {
      const supportedBrowsers = ['Chrome', 'Edge'];

      for (let i = 0; i < supportedBrowsers.length; i++) {
        if (navigator.userAgent.indexOf(supportedBrowsers[i]) > -1) {
          this.isSupportedBrowser = true;
          break;
        }
      }
    },

    onDfuLogProgress(stage, done, total) {
      if (stage === 'erase') {
        this.eraseProgress = ((done / total) * 100).toFixed(0)

      } else if (stage === 'write') {
        this.dfuProgress = ((done / total) * 100).toFixed(0)
      }
    },

    onFwImageDownloaded(buffer) {
      this.fwImage = buffer;
      this.handleStatus('imageDownloaded');
    },

    handleNext() { this.handleStatus('devicePluggedIn'); },

    async handleConnect() { await dfuUtil.connect(); },

    async handleUpdate() { await dfuUtil.updateDevice(this.fwImage); },

    async handleSelectDevice() { this.handleStatus('deviceSelected') },

    async handleStatus(status) {
      if (this.done || this.downloadError) {
        return;
      }

      if (status == 'selectDevice') {
        this.instructions = 'Select your Device from the dropdown menu.'

      } else if (status == 'deviceSelected') {
        this.instructions = ``
        const deviceName = (this.products.find(product => product.title === this.selectedDevice)).name
        const imagePath = this.imagePaths[deviceName]
        await firebaseHelper.getFwImage(imagePath)

      } else if (status == 'imageDownloaded') {
        this.instructions = 'While holding the button next to the device\'s USB connector, plug the device into your PC.\nThen, click "next".'

      } else if (status == 'devicePluggedIn') {
        this.instructions = 'Click the "connect" button.\nIn the pop-up, select the "DFU in FS Mode" entry and click "connect".'

      } else if (status == 'connected') {
        this.instructions = 'Click "update".'

      } else if (status == 'updating') {
        this.instructions = 'Device is updating, do not unplug...'

      } else if (status == 'downloadError') {
        this.instructions = 'A download error occured, please refresh the page and try again.'
        this.downloadError = true;

      } else if (status == 'done') {
        this.instructions = 'Firmware updated successfully!  It is now safe to disconnect the device, enjoy!'
        this.done = true

      } else if (status == 'disconnected') {
        this.instructions = 'Device unexpectedly disconnected, please refresh the page and try again.'
      }
      
      this.status = status
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";

.heading {
  font-size: $text-size--xxl;
  font-weight: bold;
  margin: 10px;
  text-align: center;
}

.note-text {
  font-size: $text-size--sm;
  text-align: left;
  margin: 10px;
  text-align: center;
}

.dfu-instructions {
  margin: 20px;
  border: 2px;
  border-color: $color--grey2;
  border-style: solid;
  border-radius: 10px;
  min-height: 250px;
}

.dfu-instructions-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  border-bottom: 1px solid $color--grey2;
}

.dfu-instructions-heading-title {
  font-weight: bold;
  font-size: $text-size--xl;
}

.dfu-instructions-heading-selectedDevice {
  font-size: $text-size--lg;
}

.dfu-instructions-content {
  font-size: $text-size--lg;
  margin: 20px;
  min-height: 100px;
  white-space: pre-wrap;
}

.dfu-content {
  justify-content: center;
  margin: 20px;
  border: 2px;
  border-color: $color--grey2;
  border-style: solid;
  border-radius: 10px;
}

.dfu-device-selection {
  justify-content: center;
  text-align: center;
  font-size: $text-size--lg;
  margin: 20px;
  padding: 10px;
  border-bottom: 1px solid $color--grey2;
  min-height: 135px;
}

#dfu-device-dropdown {
  font-size: $text-size--lg;
  color: black;
  margin: 10px;
  padding: 10px;

  border: 4px;
  border-color: $color--cyan;
  border-style: solid;
  border-radius: 10px;
}

.dfu-device-dropdown-item {
  font-size: $text-size--md;
  padding: 20px;
  color: black;
}

.dfu-controls {
  display: flex;
  justify-content: space-evenly;
  padding: 40px;
  border-bottom: 1px solid $color--grey2;
  min-height: 135px;
}

.dfu-progress {
  margin: 20px;
}

.dfu-progress-bar {
  margin: 20px;
}
</style>