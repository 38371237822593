<template>
  <div class="productvideo-tabs">
    <div class="productvideo-tab-buttons-contianer">
      <button class="productvideo-tab-buttons" v-for="(video, index) in videos" :key="index" @click="selectTab(index)"
        :class="{ 'productvideo-tab-active': idx === index }">
        {{ video.title }}
      </button>
    </div>
  </div>
  <div class="productvideo-player-container">
    <VueYtframe :video-id="videos[idx].id" :player-vars="{ autoplay: 0, listType: 'user_uploads' }" ref="ytFrame" />
  </div>
  <div class="productvideo-note" v-if="videos[idx].note">
    {{ videos[idx].note }}
  </div>
</template>


<script>
export default {
  name: 'ProductPageVideoTabs',
  components: {
  },
  props: {
    videos: Array
  },
  data() {
    return {
      idx: 0
    }
  },
  methods: {
    async pauseVideo() {
      if (this.$refs.ytFrame) {
        await this.$refs.ytFrame.pauseVideo();
      }
    },
    async selectTab(index) {
      await this.pauseVideo()
      this.idx = index
    }
  }
}
</script>



<style lang="scss">
@import "@/styles/productPageStyles.scss";

.productvideo-tab {
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  justify-items: center;
}

.productvideo-tab-buttons-contianer {
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
}

.productvideo-tab-buttons {
  font-size: $text-size--sm;
  color: $color--black;
  background-color: $color--grey4;

  border-radius: 5px;
  border: 0px;

  transition: all .1s;

  width: 100%;

  margin: 5px;
  padding: 5px 15px 5px 15px;
}

.productvideo-tab-active {
  background-color: $color--white;
  font-weight: bold;
  filter: drop-shadow(0 0 4px $color--white);
}

.productvideo-player-container {
  padding: 20px 0;
  margin: 0 auto;
  justify-items: center;
  height: 478px;
  width: 850px;
}

.productvideo-note {
  padding: 10px 0;
  margin: 0 auto;
  text-align: center;
  font-size: $text-size--xs;
  color: $color--grey4;
}

@media only screen and (max-width: $bkpt--lg) {
  .productvideo-tab-buttons {
    font-size: $text-size--xs;
  }

  .productvideo-player-container {
    height: 440px;
    width: 712px;
  }
}

@media only screen and (max-width: $bkpt--md) {
  .productvideo-player-container {
    height: 320px;
    width: 500px;
  }

  .productvideo-note {
    padding: 5px 0;
    font-size: $text-size--xxs;
  }
}

@media only screen and (max-width: $bkpt--sm) {
  .productvideo-tab-buttons-contianer {
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
  }

  .productvideo-tab-buttons {
    margin: 5px 0;
  }

  .productvideo-player-container {
    height: 275px;
    width: 420px;
  }
}

@media only screen and (max-width: $bkpt--xs) {
  .productvideo-player-container {
    height: 190px;
    width: 260px;
  }
}

@media only screen and (max-width: $bkpt--xxs) {
  .productvideo-player-container {
    height: 160px;
    width: 200px;
  }
}
</style>