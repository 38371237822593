<template>
  <div class="head">
    <Nav />
    <div class="cart-container">
      <router-link to="/cart">
          <div class="cart-icon-container">
            <div v-if="numCartItems>0">
              <span class='cart-quantity'>
                <div class='cart-quantity-price'>{{numCartItems}}</div>
                </span>
            </div>
            <img class="cart-icon" style="width: 100%;" src="@/assets/cart_icon2_freeshipping.svg"/>
          </div>
      </router-link>
    </div>
    <div class="header-logo">
      <router-link to="/home">
        <img v-if="isLogoLarge" style="width: 100%;" src="@/assets/Logo_web_header_light_italic.png" alt="Tark Audio Logo">
        <img v-else style="width: 100%;" src="@/assets/logo.png" alt="Tark Audio Logo">
      </router-link>
    </div>
  </div>
</template>


<script>
import Nav from './Nav.vue'

import { cartManager } from '@/js/CartManager.js'

export default {
  name: 'Header',
  components: {
    Nav
  },
  data() {
    return {
      lastWidth: 577,
      isLogoLarge: true,
      numCartItems: 0
    }
  },
  created() {
    this.logo_src = this.logo_large
  },
  mounted() {
    cartManager.on('updated', this.cartUpdated)
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
    this.cartUpdated()
  },
  unmounted() {
    cartManager.off('updated', this.cartUpdated)
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    windowResize() {
      var width = document.documentElement.clientWidth;
      // var height = document.documentElement.clientHeight;  // unused

      if( (width < 576) && (this.lastWidth >= 576) ) {
        this.isLogoLarge = false;
      }
      else if( (width > 576) && (this.lastWidth <= 576) ) {
        this.isLogoLarge = true;
      }

      this.lastWidth = width;
    },
    getLogoSrc() {
      return this.logo_large
    },
    cartUpdated() {
      this.numCartItems = cartManager.getTotalNumberOfItems()
    }
  }
}
</script>



<style lang="scss" scoped>
@import "@/styles/style.scss";

.head {
  width: 100%;
  display: flex;
  justify-content:space-around;
  margin-bottom: 30px;
}

.cart-container {
  padding-top: 50px;
  width: 80px;
  height: 80px;
}


.cart-quantity {
  position: absolute;
  left: 45px;
  top: -5px;
  background: $color--white;
  color: $color--black;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  z-index: 1;
  
  filter: drop-shadow(0 0 2px $color--white);
}

.cart-quantity-price {
  align-self: center;
  text-justify: center;
  text-align: center;
  font-weight: bold;
  color: $color--black;
  font-size: $text-size--sm;
  z-index: 2;
  margin-top: 2px;
}

a {
  text-decoration: none;
}

.cart-icon-container {
  position: relative;
}

.cart-icon {
  position: absolute;
  opacity: 80%;
}

.header-logo {
  width: 350px;
  padding: 20px;
}

@media only screen and (max-width: $bkpt--sm) {
  .cart-container {
    padding-top: 30px;
  }

  .header-logo {
    width: 150px;
  }
}
</style>