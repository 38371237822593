<template>
  <Page>
      <Header />
        <div class="content-container">
          <slot />
        </div>
      <Footer />
  </Page>
</template>


<script>
import Page from './Page.vue'
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  name: 'RegularPage',
  components: {
    Page,
    Header,
    Footer
  }
}
</script>


<style scoped lang="scss">
@import "@/styles/style.scss";

.content-container {
  margin: auto;
  justify-content: center;
  max-width: $content-width--max;
}
</style>