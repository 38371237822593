<template>
  <RegularPage>
    <div class="accordion" id="faq-accordion">
      <div v-for='(faq, i) in faqs' :key='i'>
        <div class="accordion-item">
          <div class="accordion-header" :id="`heading${i}`">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              :data-bs-target="`#collapse${i}`" aria-expanded="false" :aria-controls=faq.target v-html="faq.q"></button>
          </div>
          <div :id="`collapse${i}`" class="accordion-collapse collapse" :aria-labelledby="`heading${i}`"
            data-bs-parent="#faq-accordion">
            <div class="accordion-body">
              <div class="accordion-body text" v-html="faq.a"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'

export default {
  name: 'Faq',
  components: {
    RegularPage
  },
  data() {
    return {
      faqs: [
        {
          q: "What countries do you ship to?",
          a: "Currently, we only ship within the United States."
        },
        {
          q: "Why am I being redirected to Stripe when checking out?",
          a: "The Checkout button redirects to Stripe to guarantee your security."
        },
        {
          q: "What kind of warranty do Tark Audio products come with?",
          a: "Tark Audio products are covered under warranty for one year following the date of\
              purchase. This warranty covers any defect in the manufacturing of this product. This\
              warranty does not cover any damage or malfunction caused by incorrect use - such as,\
              but not limited to, incorrect power application, excessive voltage levels, or exposure to\
              extreme temperature or moisture levels.<br><br>\
              The warranty covers replacement or repair, as decided by Tark Audio. Please contact\
              our customer service via our Contact Us Webpage (tarkaudio.com/contactus) for a return\
              authorization before sending the device. A dated Proof of Purchase is required to execute\
              this warranty. The cost of sending a device back for servicing is paid for by the customer."
        },
      ]
    }
  }
}
</script>


<style lang="scss">
@import "@/styles/style.scss";

.accordion {
  margin: auto 20px;
}

.accordion-item {
  margin: 60px 0;
}

.accordion-button {
  font-size: $text-size--md !important;
  color: $color--white !important;
  background-color: $color--grey6 !important;
}

.accordion-button.collapsed {
  background-color: $color--grey7 !important;
}

.accordion-button.collapsed::after {
  background-image: url('@/assets/navMenu_icon.svg');
}

.accordion-body {
  font-size: $text-size--md;
  background-color: $color--grey5;
  color: $color--white;
  border-radius: 10px;
}

.accordion-body.text {
  padding: 10px;
}

// removes blue box on active
:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
</style>