<template>
  <router-link :to="route">
    <div class="card-text title">{{ title }}</div>
    <img class="card-image" :src="require(`@/assets/${imgSrc}`)" />
    <div class="card-text-description">{{ description }}</div>
    <ProductPrice :price="price" :salePrice="salePrice" :onSale="onSale"/>

  </router-link>
</template>


<script>
import ProductPrice from "@/components/ProductPrice.vue"

export default {
  name: 'ProductCard',
  components: {
    ProductPrice
  },
  props: {
    route: String,
    title: String,
    imgSrc: String,
    description: String,
    price: Number,
    salePrice: Number,
    onSale: Boolean
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";

.card-text {
  color: $color--white;
  font-size: $text-size--xxl;
  font-weight: bold;
}

.card-text-description {
  font-size: $text-size--lg;
  margin: 10px 0;
  font-weight: normal;
  font-style: italic;
}

.card-text-price {
  font-size: $text-size--xl;
  font-weight: bold;
}

.card-image {
  filter: drop-shadow(0 0 7px rgba(255, 255, 255, 0.7));
  max-height: 300px;
}

a {
  text-decoration: none;
}
</style>