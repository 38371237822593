<template>
  <div v-if="!accepted" class="cookie-banner">
    <div class="cookies-banner-text">We use cookies to ensure you get the best experience on our website. <a
        href="https://cookiesandyou.com/" target="_blank">Learn More</a></div>
    <div class="cookies-banner-button-container">
      <button class="cookies-banner-button" @click="acceptCookies">Accept</button>
    </div>
  </div>
</template>


<script>
import { firebaseHelper } from '@/js/FirebaseHelper.js'

export default {
  name: 'CookieBanner',
  data() {
    return {
      accepted: false
    }
  },
  mounted() {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      this.accepted = true;
      firebaseHelper.enableAnalytics();
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.accepted = true;
      firebaseHelper.enableAnalytics();
    },
  }
}
</script>


<style lang="scss">
@import "@/styles/style.scss";

.cookie-banner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: $color--white;
  padding: 10px;
  text-align: center;
  border: 4px solid $color--white;
  border-radius: 10px;
}

.cookie-banner a {
  color: $color--white;
  font-size: $text-size--xs;
}

.cookies-banner-text {
  font-size: $text-size--xs;
}

.cookies-banner-button-container {
  padding: 0 10px;
}

.cookies-banner-button {
  height: 50px;
  width: 120px;

  font-size: $text-size--xs;
  font-weight: bold;
  color: $color--black;
  background-color: $color--green;

  border-radius: 10px;
  border: 0px;
}


@media only screen and (max-width: $bkpt--md) {
  .cookie-banner a {
    color: $color--white;
    font-size: $text-size--xxs;
  }

  .cookies-banner-text {
    font-size: $text-size--xxs;
  }

  .cookies-banner-button {
    font-size: $text-size--xs;
  }
}
</style>