import { EventEmitter } from "events";
import { firebaseHelper } from './FirebaseHelper'
import { Product } from '@/js/ProductHelper.js'

export const cartManager = new class CartManager extends EventEmitter {
  constructor() {
    super();
    this.items = [];
  }

  async init() {
    const cartItemsJSON = localStorage.getItem('cartItems');
    if (cartItemsJSON) {
      const cartItems = JSON.parse(cartItemsJSON, (key, value) => {
        if (key === 'product') {
          return Product.fromJSON(value);
        }
        return value;
      });
  
      this.items = cartItems;
    }
  }

  clear() {
    this.items.splice(0, this.items.length)
    this.update()
  }

  getItemIndex(item) {
    return this.items.findIndex(_item => { return _item.product.isEqual(item.product)} )
  }

  getItemQuantity(item) {
    const i = this.getItemIndex(item)
    if (i > -1) {
      return this.items[i].quantity
    }

    return 0
  }

  createItem(opts) {
    const item = {
      product: new Product(opts),
      quantity: 1,
    }

    return item;
  }

  getItems() {
    return this.items
  }

  getNumerOfItems() {
    return this.items.length
  }

  getTotalNumberOfItems() {
    var qty = 0
    this.items.forEach((item) => {
      qty += item.quantity
    })
    return qty
  }

  addItem(item) {
    if (this.incrementQuantity(item)) { }  // do nothing, quantity incremented
    else this.items.push(item);
    this.update()
  }

  incrementQuantity(item) {
    const i = this.getItemIndex(item)
    if (i > -1) {
      if (this.items[i].quantity < this.items[i].product.getData('quantityMax')) {
        this.items[i].quantity++
      }
      this.update()
      return true
    }

    return false
  }

  removeItem(item) {
    this.items = this.items.filter(_item => { return !_item.product.isEqual(item.product)} )
    this.update()
  }

  decrementQuantity(item) {
    const i = this.getItemIndex(item)
    if (i > -1) {
      this.items[i].quantity--
      if (this.items[i].quantity <= 0) this.removeItem(item)
      this.update()
      return true
    }

    return false
  }

  update() {
    const itemsJSON = JSON.stringify(this.items)
    localStorage.setItem('cartItems', itemsJSON)

    this.emit('updated')
  }

  getItemData(item, dataKey) {
    if( dataKey === 'quantity' ) {
      return item.quantity
    }

    return item.product.getData(dataKey)
  }

  async getItemStock(item) { return await item.product.getStock() }

  async checkout(router) {
    const lineItems = []
    this.items.forEach((item) => {
      lineItems.push({
        name: item.product.getName(),
        variantId: item.product.getVariantId(),
        priceId: item.product.getData('priceId'),
        quantity: item.quantity
      })
    })

    const stripeCreateCart = firebaseHelper.getHttpsCallableFunction('stripeCreateCart')
    const response = await stripeCreateCart({ lineItems: lineItems })

    if (response.data.error) {
      router.push('/cart-error')

    } else if (response.data.checkoutUrl) {
      window.location.href = response.data.checkoutUrl;
    }
  }
}