import { createRouter, createWebHistory } from 'vue-router'

import NotFound from '@/views/NotFound'
import Home from '@/views/Home'
import Products from '@/views/Products'
import About from '@/views/About'
import Faq from '@/views/Faq'
import FirmwareUpdate from '@/views/FirmwareUpdate'
import Cart from '@/views/Cart'
import CartError from '@/views/CartError'
import StripeConfirmation from '@/views/StripeConfirmation'
import StripePaymentThankYou from '@/views/StripePaymentThankYou'
import StripePaymentIncomplete from '@/views/StripePaymentIncomplete'
import ContactUs from '@/views/ContactUs'

import ProductPage_SquidInk from '@/views/products/ProductPage_SquidInk'
import ProductPage_Worf from '@/views/products/ProductPage_Worf'
import ProductPage_Tark144 from '@/views/products/ProductPage_Tark144'
import ProductPage_LambdaOsc from '@/views/products/ProductPage_LambdaOsc'
import ProductPage_EchoSync from '@/views/products/ProductPage_EchoSync'
import ProductPage_Tark144Euro from '@/views/products/ProductPage_Tark144Euro'
import ProductPage_MortysFuzzDrive from '@/views/products/ProductPage_MortysFuzzDrive'


const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFound
  },
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/products/squidink',
    name: 'SquidInk',
    component: ProductPage_SquidInk
  },
  {
    path: '/products/worf',
    name: 'Worf',
    component: ProductPage_Worf
  },
  {
    path: '/products/tark144',
    name: 'Tark144',
    component: ProductPage_Tark144
  },
  {
    path: '/products/lambdaosc',
    name: 'LambdaOsc',
    component: ProductPage_LambdaOsc
  },
  {
    path: '/products/echosync',
    name: 'EchoSync',
    component: ProductPage_EchoSync
  },
  {
    path: '/products/tark144euro',
    name: 'Tark144Euro',
    component: ProductPage_Tark144Euro
  },
  {
    path: '/products/mortysfuzzdrive',
    name: 'Mortys Fuzz Drive',
    component: ProductPage_MortysFuzzDrive
  },
  {
    path: '/firmwareupdate',
    name: 'Firmware Update',
    component: FirmwareUpdate
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/cart-error',
    name: 'CartError',
    component: CartError,
    excludeFromHistory: true
  },
  {
    path: '/stripe-confirmation',
    name: 'Stripe Confirmation',
    component: StripeConfirmation,
    excludeFromHistory: true
  },
  {
    path: '/stripe-payment-thank-you',
    name: 'Stripe Payment Thank You',
    component: StripePaymentThankYou,
    excludeFromHistory: true
  },
  {
    path: '/stripe-payment-incomplete',
    name: 'Stripe Payment Incomplete',
    component: StripePaymentIncomplete,
    excludeFromHistory: true
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: ContactUs
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
