<template>
  <RegularPage>
    <ProductPageShowcase :productName="name" :userManualPath="`${userManualPath}`" :quickSpecs="[
      { key: 'TYPE:', value: 'Color/Dynamics' },
      { key: 'DIMS:', value: '2.7x5x2.3in' },
      { key: 'POWER:', value: '+9 VDC' },
      { key: 'CURRENT:', value: '30mA' }
    ]"/>

    <!-- BEGIN sections -->
    <div class="sections">

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">VIDEOS</div>
        <ProductPageVideoTabs :videos="[
          { title: 'Boring Gear Reviews', id: 'vOKZCrEIFkQ', note: '' },
          { title: 'Pedal Experiments', id: '0Zbhlz6mt-4'}
          // , note: 'note: full counter-clockwise on volume knob makes the output zero on production units' }
        ]" />
      </div>

      <!-- TODO: update samples -->
      <!-- <div class="section-container ta-section">
        <div class="section-title ta-section-title">SAMPLES</div>
        <div class="section-content ta-section-content">
          <ProductPageSampleScope :samples="[
            { desc: 'Bite Rotation', path: `${publicPath}static/products/tark144/audio/bite_rotation.wav` },
            { desc: 'Bite 0%', path: `${publicPath}static/products/tark144/audio/bite_0.wav` },
            { desc: 'Bite 50%', path: `${publicPath}static/products/tark144/audio/bite_50.wav` },
            { desc: 'Bite 100%', path: `${publicPath}static/products/tark144/audio/bite_100.wav` },
          ]" />
        </div>
      </div> -->

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">DESCRIPTION</div>
        <div class="section-content long-description ta-section-content">
          The TARK 144 is a faithful replica of a vintage cassette tape machine channel strip. The circuit consists of a
          JFET preamp followed by an active tone control.<br /> <br />

          The TARK 144 elaborates on the channel strip design by adding a saturating dynamics circuit between the
          JFET preamp and tone control circuitry. This circuit was added to emulate the noise reduction compander
          characteristic of early tape machines. The TRIM knob setting greatly effects the action of the dynamics. The
          dynamics circuit can be switched ON and OFF with the DYN switch.<br /> <br />

          The TARK 144 has a switchable input impedance to give an authentic “plugging straight into a tape machine” feel
          and sound. It also has a VU Meter to display the output level.<br /> <br />

          The TARK 144 has a mechanical True-Bypass footswitch and is powered by a +9V DC, center-negative power supply
          and consumes a maximum of 30mA.
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">SPECS</div>
        <div class="section-content ta-section-content">
          <div class="specs">
            <div class="specs-key">Operating Voltage:</div>
            <div class="specs-value">+9VDC</div>
            <div class="specs-key">Current Draw:</div>
            <div class="specs-value">30mA (max)</div>
            <div class="specs-key">Input Impedance:</div>
            <div class="specs-value">1 MOhm, 68 kOhm</div>
            <div class="specs-key">Output Impedance:</div>
            <div class="specs-value">100 Ohm</div>
            <div class="specs-key">Bypass:</div>
            <div class="specs-value">Mech. True-Bypass</div>
            <div class="specs-key">Dimensions:</div>
            <div class="specs-value">2.7” x 5” x 2.3”</div>
            <div class="specs-key">Weight:</div>
            <div class="specs-value">300 grams</div>
            <div class="specs-key">Jack Position:</div>
            <div class="specs-value">Top</div>
          </div>
        </div>
      </div>

      <div class="section-container ta-section">
        <div class="section-title ta-section-title">RESOURCES</div>
        <div class="section-content ta-section-context">
          <a class="productpage-user-manual" :href="`${userManualPath}`" target="_blank">User
            Manual</a>
        </div>
      </div>

      <!-- END sections -->
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import ProductPageShowcase from '@/components/ProductPageShowcase.vue'
import ProductPageSampleScope from '@/components/ProductPageSampleScope.vue'
import ProductPageVideoTabs from '@/components/ProductPageVideoTabs.vue'

import { getStaticProductDetails } from '@/js/StaticProductDetails.js'


export default {
  name: 'ProductPage_Tark144',
  components: {
    RegularPage,
    ProductPageShowcase,
    ProductPageSampleScope,
    ProductPageVideoTabs
  },
  data() {
    return {
      name: 'tark144',
      publicPath: process.env.BASE_URL,
      userManualPath: null
    }
  },
  created() {
    this.userManualPath = `${this.publicPath}${getStaticProductDetails(this.name).userManual}`
  }
}
</script>


<style lang="scss">
@import "@/styles/productPageStyles.scss";
</style>