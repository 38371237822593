<template>
  <RegularPage>
    <div class="productCategoriesContainer">
      <div class="productCategoriesItem" v-for="category in productCategories">
        <div :class="{ productCategoriesItemActive: this.activeProductCategories.includes(category) }"
          v-on:click="toggleCategoryActivation(category)">{{ category }}</div>
      </div>
    </div>

    <div class="productCardContainer">
      <div v-for="(product, i) in products" :key="i">
        <div v-if="this.activeProductCategories.includes(product.category)">
          <div class="productCardItem">
            <ProductCard :route=product.route :title=product.title :imgSrc=product.cardImg
              :description=product.shortDescription :price=product.variants[0].price :salePrice=product.variants[0].salePrice :onSale=product.variants[0].onSale />
          </div>
        </div>
      </div>
      
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'
import ProductCard from "@/components/ProductCard.vue"

import { getAllStaticProductDetails } from '@/js/StaticProductDetails.js'

export default {
  name: 'Products',
  components: {
    RegularPage,
    ProductCard
  },
  data() {
    return {
      products: [],
      productCategories: [],
      activeProductCategories: [],
      activeColor: 'red',
      firstClick: true
    }
  },
  created() {
    var productDetails = getAllStaticProductDetails()
    let categories = []

    for (const [i, product] of productDetails.products.entries()) {
      if (product.display) {
        categories.push(product.category)
        this.products.push(product)
      }
    }

    this.productCategories = [...new Set(categories)]

    if (this.$route.query.category === 'all' || this.$route.query.category == undefined) {
      this.activeProductCategories = [...new Set(this.productCategories)]

    } else {
      this.toggleCategoryActivation(this.$route.query.category.toUpperCase())
    }
  },
  mounted() {
    this.firstClick = true;
  },
  methods: {
    toggleCategoryActivation(category) {
      if (this.firstClick && this.productCategories.length == this.activeProductCategories.length) {
        this.firstClick = false
        this.activeProductCategories = this.activeProductCategories.filter(item => item === category)
        return
      }

      if (this.activeProductCategories.includes(category)) {
        // always keep at least one category selected
        if (this.activeProductCategories.length === 1) {
          return;
        }

        this.activeProductCategories = this.activeProductCategories.filter(item => item !== category)

      } else {
        this.activeProductCategories.push(category)
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";

.productCategoriesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 90%;

  border-bottom: 1px solid $color--grey2;
}

.productCategoriesItem {
  flex-basis: 50%;
  box-sizing: border-box;
}

.productCategoriesItemActive {
  color: $color--green;
  font-weight: bold;
}

.productCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.productCardItem {
  text-align: center;
  flex-basis: 100%;
  box-sizing: border-box;

  margin: 40px 80px 0 80px;
}

@media only screen and (max-width: $bkpt--md) {
  .productCardItem {
    flex-basis: 100%;
  }
}
</style>