import p5 from 'p5'
import { defineSketch } from './p5_helper'

class Star {
  constructor(p, x, y) {
    this.p = p

    this.pos = this.p.createVector(x, y);
    this.prevPos = this.p.createVector(x, y);

    this.vel = this.p.createVector(0, 0);

    this.ang = this.p.atan2(y - this.p.height / 2, x - this.p.width / 2)

    const minRGB = 27;
    const maxRGB = 255;
    
    this.color = {
      r: Math.floor(Math.random() * (maxRGB - minRGB + 1) + minRGB),
      g: Math.floor(Math.random() * (maxRGB - minRGB + 1) + minRGB),
      b: Math.floor(Math.random() * (maxRGB - minRGB + 1) + minRGB)
    }
  }

  update(acc) {
    this.vel.x += this.p.cos(this.ang) * acc;
    this.vel.y += this.p.sin(this.ang) * acc;

    this.prevPos.x = this.pos.x;
    this.prevPos.y = this.pos.y;

    this.pos.x += this.vel.x;
    this.pos.y += this.vel.y;
  }

  draw() {
    const alpha = this.p.map(this.vel.mag(), 0, 1, 0, 255);
    this.p.stroke(`rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${alpha})`)

    const weight = this.p.map(this.vel.mag(), 0, 1, 0, 3);
    this.p.strokeWeight(weight)

    this.p.line(this.pos.x, this.pos.y, this.prevPos.x, this.prevPos.y);
  }

  isActive() {
    const x = this.prevPos.x
    const y = this.prevPos.y
    return x >= 0 && x <= this.p.width && y >= 0 && y <= this.p.height;
  }
}

export class StarsAnimClass {
  constructor(canvasParent, width, height) {
    this.canvasParent = canvasParent
    this.width = width
    this.height = height

    this.p = {}       // pointer to p5
    this.canvas = {}  // pointer to canvas

    this.mySketch = defineSketch(this)
    new p5(this.mySketch)

    this.numStars = 250;
    this.stars = [];
    this.acc = 0.005
  }

  setCanvasParent(canvasParent) {
    if (this.canvas) {
      this.canvasParent = canvasParent
      this.canvas.parent(this.canvasParent)
    }
  }

  resize(dims) {
    if (this.p) {
      this.width = dims.width
      this.height = dims.height
      this.p.resizeCanvas(this.width, this.height)
    }
  }

  // setup function for p5
  setup = (p) => {
    this.p = p
    this.canvas = p.createCanvas(this.width, this.height)
    this.canvas.parent(this.canvasParent)
    this.p.textFont('Inconsolata')

    for (let i = 0; i < this.numStars; i++) {
      this.stars.push(new Star(p, p.random(p.width), p.random(p.height)))
    }

    this.acc = p.map(p.width, 375, 800, 0.0035, 0.005);
  }

  // draw function for p5
  draw = (p) => {
    p.background(0);

    this.stars = this.stars.filter(star => {
      star.draw();
      star.update(this.acc);
      return star.isActive();
    });

    while (this.stars.length < this.numStars) {
      this.stars.push(new Star(p, p.random(p.width), p.random(p.height)));
    }
  }

  cleanup() {
    try {
      this.p.noCanvas();
    } catch (e) {
      console.log("starsAnim - no canvas found to remove");
      console.log(e);
    }
  }
}
