<template>
  <RegularPage>
    <div class="error">Error 404</div>
    <div class="text">This is not page you are looking for.</div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'

export default {
  name: 'NotFound',
  components: {
    RegularPage
  }
}
</script>


<style lang="scss" scoped>
@import "@/styles/style.scss";
.error {
  font-size: $text-size--xxl;
  font-weight: bold;
  margin: 0 10%;
}

.text {
  font-size: $text-size--xl;
  text-align: left;
  margin: 0 10%;
}
</style>