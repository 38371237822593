<template>
  <RegularPage>
    <div class="content-wrapper" id="content-wrapper-id">
      <div class="content-wrapper-bg">
        <div id="starsAnim-canvas" />
      </div>
      <div class="inner-content-wrapper">
        <div class="message-text">TARK 144 AVAILABLE NOW</div>
        <div class="pedal-image-container">
          <router-link to="/products/tark144">
            <img class="pedal-image" src="@/assets/tark144/0_top_crop.png" />
          </router-link>
          <!-- <router-link to="/products/tark144euro">
            <img class="pedal-image" src="@/assets/tark144euro/01_crop.png" />
          </router-link> -->
        </div>
        <div class="message-text">FREE SHIPPING ON ALL ORDERS</div>
      </div>
    </div>
  </RegularPage>
</template>


<script>
import RegularPage from '@/components/RegularPage.vue'

import { StarsAnimClass } from '@/js/StarsAnim.js'

export default {
  name: 'Home',
  components: {
    RegularPage
  },
  mounted() {
    window.addEventListener('resize', this.windowResize)

    const dims = this.calcStarsAnimDims()
    this.starsAnim = new StarsAnimClass(
      'starsAnim-canvas',
      dims.width,
      dims.height)
  },
  unmounted() {
    window.removeEventListener('resize', this.windowResize)
    this.starsAnim.cleanup()
  },
  methods: {
    windowResize() {
      this.starsAnim.cleanup()

      const dims = this.calcStarsAnimDims()
      this.starsAnim = new StarsAnimClass(
        'starsAnim-canvas',
        dims.width,
        dims.height)
    },
    calcStarsAnimDims() {
      return {
        width: document.getElementById('content-wrapper-id').clientWidth,
        height: document.getElementById('content-wrapper-id').clientHeight
      }
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/styles/style.scss";

.content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 650px;
  justify-content: center;
}

.content-wrapper-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;
  overflow: hidden;
}

.inner-content-wrapper {
  padding: 40px;
}

.message-text {
  color: $color--white;
  text-align: center;
  font-weight: bold;
  font-size: $text-size--xxl;
  margin: 20px 0;
  text-shadow: $color--black 0 0 5px;
}

.pedal-image-container {
  display: flex;
  justify-items: center;
  justify-content: center;
  margin: auto;
  padding: 20px;
}

.pedal-image {
  filter: drop-shadow(0 0 10px rgba(128, 0, 128, 5));
  max-height: 400px;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: $bkpt--md) {
  .pedal-image-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: $bkpt--xs) {
  .message-text {
    font-size: $text-size--lg;
  }
}

@supports (-webkit-touch-callout: none) {

  /* CSS specific to iOS devices */
  .content-wrapper {
    min-height: 100vh;
  }

  .content-wrapper-bg {
    object-fit: cover;
  }
}

@supports not (-webkit-touch-callout: none) {

  /* CSS for other than iOS devices */
  .content-wrapper-bg {
    object-fit: fill;
  }
}
</style>